import React from 'react';
import { Heading } from '@cdk-rds/typography';
import { Body1 } from '@fortellis/typography';
import { DropdownMenu } from 'cdk-radial';
import config from '../../../../config/config';
import LearnMore from '../../../learnmore/LearnMore';

import {
  API_ENVS_FOR_APPS,
  getEnvOption,
  ALL_APIS_OPTION
} from '../../../metrics/utils';

import './app-metrics.scss';
import { Grid, Column, Row } from 'cdk-radial';

function getApiOption(apiOptionsList, apiOptionValue) {
  if (apiOptionValue === ALL_APIS_OPTION.value) {
    return ALL_APIS_OPTION;
  }
  return apiOptionsList.find(each => each.value === apiOptionValue);
}

function AppMetricsHeader({
  appName,
  apiOptions,
  isAppLoading,
  isMetricsLoading,
  apiSelected,
  onApiChange,
  environmentSelected,
  onEnvironmentChange
}) {
  let name = isAppLoading ? 'Loading...' : appName || 'Unknown App';
  return (
    <>
      <div className="app-metrics-top-heading">
        <Heading headingType="heading-1" level={1}>
          App Metrics
        </Heading>
        <br />
        <Body1 weight={400}>
          Monitor the traffic, latency, and error rates over time of the APIs
          used by the app. Filter and use these performance metrics to inspect
          the system responsiveness and to isolate errors.{' '}
          <LearnMore
            url={`${config.nav.docs.url}/docs/tutorials/app-lifecycle/monitoring-apps/#app-metrics`}
          />
        </Body1>
        <br />
      </div>
      <div className="app-metrics-header">
        <Grid>
          <Row style={{ gap: '16px', alignItems: 'center' }}>
            <Column l={5}>
              <Heading
                headingType="heading-1"
                style={{ overflowWrap: 'break-word' }}
                level={1}
              >
                {name}
              </Heading>
              <Body1 weight={400}>
                This is a detailed view of your App performance over time.
              </Body1>
            </Column>
            <Column>
              <DropdownMenu
                className="app-metrics-header-dropdown"
                inputId="api"
                label="API"
                onChange={e => {
                  onApiChange({ apiId: e.value, kind: e.kind });
                }}
                options={[ALL_APIS_OPTION, ...(apiOptions && apiOptions)]}
                value={getApiOption(apiOptions, apiSelected)}
                isDisabled={isMetricsLoading}
              />
            </Column>
            <Column>
              <DropdownMenu
                className="app-metrics-header-dropdown"
                inputId="environment"
                label="Environment"
                onChange={e => {
                  onEnvironmentChange({ environment: e.value });
                }}
                options={API_ENVS_FOR_APPS}
                value={getEnvOption(environmentSelected)}
                isDisabled={isMetricsLoading}
              />
            </Column>
          </Row>
        </Grid>
      </div>
    </>
  );
}

export default AppMetricsHeader;
