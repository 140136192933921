import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { EntityProvider as FrcEntityProvider } from '@cdk-prod/fortellis-entity-context';
import config from '../../config/config';
import { useOktaAuth } from '@okta/okta-react';

const ORG_CHANGE_REDIRECT = '/developer-account';

function EntityProvider({ auth, children }) {
  const { oktaAuth } = useOktaAuth();

  async function updateEntity(entityId) {
    await oktaAuth.signInWithRedirect({
      originalUri: ORG_CHANGE_REDIRECT,
      state: `${entityId !== 'personal-account' ? entityId : '*'}`
    });
  }

  return (
    <FrcEntityProvider
      accessToken={auth.accessToken || ''}
      authenticated={auth.isAuthenticated}
      env={config.env}
      userId={(auth.userData && auth.userData.sub) || ''}
      // secure={false} // Add this line for allowing EntityProvider to read from localhost cookie
      updateEntity={updateEntity}
    >
      {children}
    </FrcEntityProvider>
  );
}

EntityProvider.propTypes = {
  auth: PropTypes.object.isRequired
};

export default withAuth(EntityProvider);
