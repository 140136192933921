import { useReducer } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { ALL_APIS_OPTION, getIntervalFromTimeRange } from './utils';
import { apiTypes } from '../developer-account/api/constants';
const APIGATEWAY_BASE_URL = config.api.apiGatewayBaseUrl;
const ASYNC_API_SERVICE_URL = config.api.asyncApiServiceBaseUrl;
const SOLUTION_URL = config.api.solutionUrl;
const NA = 'N/A';
const UNKNOWN = 'unknown';

const initialState = {
  isloading: false,
  error: null,
  response: null,
  isRequestFulfilled: false
};

function buildQueryString(input) {
  let queryString = '';
  for (const [key, value] of Object.entries(input)) {
    if (key && value) {
      queryString += `&${key}=${value}`;
    }
  }
  return queryString;
}

function isValid(name) {
  return name !== NA && name !== UNKNOWN;
}

// Some of metrics results will contain incomplete data such as N/A, unknown because of early exit at runtime, so filter them for now until we have a mechanism to fill-in gaps in the telemetry pipeline
function filterDataAppendMaxForAggMetrics(metrics = []) {
  let filteredData = metrics.filter(
    each =>
      isValid(each.apiName) &&
      isValid(each.apiId) &&
      isValid(each.proxyRevision) &&
      isValid(each.environment)
  );
  let dataWithMaxValue = filteredData.map(each => {
    let values = each.values.map(each => parseInt(each.value));
    let maxValue = Math.max(...values);
    return {
      ...each,
      maxValue
    };
  });
  return dataWithMaxValue;
}

// Some of metrics results will contain incomplete data such as N/A, unknown because of early exit at runtime, so filter them for now until we have a mechanism to fill-in gaps in the telemetry pipeline
function filterDataAppendMaxForApiMetrics(metrics) {
  let transactions = metrics.transactions.filter(
    each => isValid(each.apiName) && isValid(each.proxyRevision)
  );
  let latency = metrics.latency.filter(
    each => isValid(each.apiName) && isValid(each.proxyRevision)
  );
  let errorRate = metrics.errorRate.filter(
    each => isValid(each.apiName) && isValid(each.proxyRevision)
  );
  // Calculate max value of transactions to compute thresholds
  // Computing here avoids unnescessary re-renders
  transactions = transactions.map(each => {
    let values = each.values.map(each => parseInt(each.value));
    let maxValue = Math.max(...values);
    return {
      ...each,
      maxValue
    };
  });
  latency = latency.map(each => {
    let values = each.values.map(each => parseInt(each.value));
    let maxValue = Math.max(...values);
    return {
      ...each,
      maxValue
    };
  });
  return {
    transactions,
    latency,
    errorRate
  };
}

function appendMaxForAppMetrics(metrics) {
  // Calculate max value of transactions to compute thresholds
  // Computing here avoids unnescessary re-renders
  const transactions = metrics.transactions.map(each => {
    let values = each.values.map(each => parseInt(each.value));
    let maxValue = Math.max(...values);
    return {
      ...each,
      maxValue
    };
  });
  const latency = metrics.latency.map(each => {
    let values = each.values.map(each => parseInt(each.value));
    let maxValue = Math.max(...values);
    return {
      ...each,
      maxValue
    };
  });
  const errorRate = metrics.errorRate;
  return {
    transactions,
    latency,
    errorRate
  };
}

function metricsReducer(state, action) {
  switch (action.type) {
    case 'request':
      return {
        ...state,
        isloading: true,
        error: null,
        isRequestFulfilled: false,
        response: null
      };
    case 'receive':
      return {
        ...state,
        isloading: false,
        error: null,
        isRequestFulfilled: true,
        response: action.data
      };
    case 'error':
      return {
        ...state,
        isloading: false,
        error: action.data,
        isRequestFulfilled: true,
        response: null
      };
    default:
      throw new Error();
  }
}

export function useApiMetricsFetch() {
  const [state, dispatch] = useReducer(metricsReducer, initialState);

  function fetchMetrics(accessToken, filters) {
    dispatch({ type: 'request', data: null });
    const qsParam = {
      apiInstanceEnvironment: filters.environment,
      timeRange: filters.timeRange,
      interval: getIntervalFromTimeRange(filters.timeRange)
    };
    if (!!filters.appId && filters.appId !== 'allApp') {
      qsParam['appId'] = filters.appId;
    }
    const qs = buildQueryString(qsParam);
    axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      url: `${config.api.metricsUrl}/metrics/apis/${filters.apiId}/versions/${filters.apiInstanceVersion}?${qs}`
    })
      .then(res => {
        dispatch({
          type: 'receive',
          data: filterDataAppendMaxForApiMetrics(res.data)
        });
      })
      .catch(error => {
        dispatch({
          type: 'error',
          data: (error && error.response && error.response.data) || {
            error: 'Failed to fetch api details metrics'
          }
        });
      });
  }
  return [
    state.isloading,
    state.isRequestFulfilled,
    state.error,
    state.response,
    fetchMetrics
  ];
}

export function useAsyncApiEventsFetch() {
  const [state, dispatch] = useReducer(metricsReducer, initialState);

  function fetchMetrics(accessToken, filters) {
    dispatch({ type: 'request', data: null });
    const reqBody = {
      select: ['count(messageSize)', 'apiId'],
      groupBy: ['apiId'],
      filter: [
        {
          attribute: 'apiId',
          operator: 'eq',
          value: [filters.apiId]
        },
        {
          attribute: 'operation',
          operator: 'eq',
          value: ['send']
        }
      ],
      timeRange: filters.timeRange,
      orderBy: ['apiId'],
      persona: 'apiPublisher'
    };
    axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      url: `${config.api.metricsUrl}/metrics/events`,
      data: reqBody
    })
      .then(response => {
        let eventsCount = 0;
        response.data.result.attributes.forEach(data => {
          data.metrics.forEach(data => {
            if (data.name === 'count') {
              eventsCount += +data.value;
            }
          });
        });
        dispatch({
          type: 'receive',
          data: eventsCount
        });
      })
      .catch(error => {
        dispatch({
          type: 'error',
          data: (error && error.response && error.response.data) || {
            error: 'Failed to fetch Async API events metrics'
          }
        });
      });
  }
  return [
    state.isloading,
    state.isRequestFulfilled,
    state.error,
    state.response,
    fetchMetrics
  ];
}

export function useAsyncApiFailedEventsFetch() {
  const [state, dispatch] = useReducer(metricsReducer, initialState);

  function fetchMetrics(accessToken, filters) {
    dispatch({ type: 'request', data: null });
    const reqBody = {
      select: ['count(messageSize)', 'apiId'],
      groupBy: ['apiId'],
      filter: [
        {
          attribute: 'apiId',
          operator: 'eq',
          value: [filters.apiId]
        },
        {
          attribute: 'status',
          operator: 'eq',
          value: ['failure']
        }
      ],
      timeRange: filters.timeRange,
      orderBy: ['apiId'],
      persona: 'apiPublisher'
    };
    axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      url: `${config.api.metricsUrl}/metrics/events`,
      data: reqBody
    })
      .then(response => {
        let failedEventsCount = 0;
        response.data.result.attributes.forEach(data => {
          data.metrics.forEach(data => {
            if (data.name === 'count') {
              failedEventsCount += +data.value;
            }
          });
        });
        dispatch({
          type: 'receive',
          data: failedEventsCount
        });
      })
      .catch(error => {
        dispatch({
          type: 'error',
          data: (error && error.response && error.response.data) || {
            error: 'Failed to fetch Async API events metrics'
          }
        });
      });
  }
  return [
    state.isloading,
    state.isRequestFulfilled,
    state.error,
    state.response,
    fetchMetrics
  ];
}

export function useAsyncApiEventsForAppFetch() {
  const [state, dispatch] = useReducer(metricsReducer, initialState);

  function fetchMetrics(appId, accessToken, filters) {
    dispatch({ type: 'request', data: null });
    const apiId =
      filters.apiId === ALL_APIS_OPTION.value ? undefined : filters.apiId;

    const reqBody = {
      select: ['count(messageSize)', 'apiId', 'apiName', 'status'],
      groupBy: ['apiId', 'status'],
      filter: [
        {
          attribute: 'appId',
          operator: 'eq',
          value: [appId]
        },
        {
          attribute: 'operation',
          operator: 'eq',
          value: ['receive']
        }
      ],
      timeRange: filters.timeRange,
      orderBy: ['apiId'],
      persona: 'appPublisher'
    };

    if (!!apiId) {
      reqBody.filter.push({
        attribute: 'apiId',
        operator: 'eq',
        value: [apiId]
      });
    }

    axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      url: `${config.api.metricsUrl}/metrics/events`,
      data: reqBody
    })
      .then(response => {
        const events = [];
        response.data.result.attributes.forEach(data => {
          let event = {};
          data.metrics.forEach(data => {
            event = { ...event, [data.name]: data.value };
          });
          events.push(event);
        });
        dispatch({
          type: 'receive',
          data: events
        });
      })
      .catch(error => {
        dispatch({
          type: 'error',
          data: (error && error.response && error.response.data) || {
            error: 'Failed to fetch Async API events metrics'
          }
        });
      });
  }
  return [
    state.isloading,
    state.isRequestFulfilled,
    state.error,
    state.response,
    fetchMetrics
  ];
}

function aggregatedApiMetricsReducer(state, action) {
  switch (action.type) {
    case 'request':
      return { ...state, isloading: true, error: null, response: null };
    case 'receive':
      return { ...state, isloading: false, error: null, response: action.data };
    case 'error':
      return { ...state, isloading: false, error: action.data, response: null };
    default:
      throw new Error();
  }
}

export function useAggregatedApiMetricsFetch() {
  const [state, dispatch] = useReducer(
    aggregatedApiMetricsReducer,
    initialState
  );

  function fetchMetrics(accessToken, filters) {
    dispatch({ type: 'request', data: null });
    const qs = buildQueryString({
      apiInstanceEnvironment: filters.environment,
      timeRange: filters.timeRange,
      interval: getIntervalFromTimeRange(filters.timeRange)
    });
    axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      url: `${config.api.metricsUrl}/metrics/apis?${qs}`
    })
      .then(res => {
        dispatch({
          type: 'receive',
          data: filterDataAppendMaxForAggMetrics(res.data)
        });
      })
      .catch(error => {
        dispatch({
          type: 'error',
          data: (error && error.response && error.response.data) || {
            error: 'Failed to fetch aggregated api metrics.'
          }
        });
      });
  }
  return [
    state.isloading,
    state.isRequestFulfilled,
    state.error,
    state.response,
    fetchMetrics
  ];
}

function apiReducer(state, action) {
  switch (action.type) {
    case 'request':
      return { ...state, isloading: true, error: null, response: null };
    case 'receive':
      return { ...state, isloading: false, error: null, response: action.data };
    case 'error':
      return { ...state, isloading: false, error: action.data, response: null };
    default:
      throw new Error();
  }
}

export function useApiFetch() {
  const [state, dispatch] = useReducer(apiReducer, initialState);

  function fetchApi(accessToken, apiId, apiType) {
    dispatch({ type: 'request', data: null });
    axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      url:
        apiType === apiTypes.ASYNC
          ? `${ASYNC_API_SERVICE_URL}/v1/async-apis/${apiId}?expand=true`
          : `${APIGATEWAY_BASE_URL}/v2/apis/${apiId}?expand=true`
    })
      .then(res => {
        dispatch({ type: 'receive', data: res.data });
      })
      .catch(error => {
        dispatch({
          type: 'error',
          data: (error && error.response && error.response.data) || {
            error: 'Failed to fetch api data'
          }
        });
      });
  }
  return [
    state.isloading,
    state.isRequestFulfilled,
    state.error,
    state.response,
    fetchApi
  ];
}

function appReducer(state, action) {
  switch (action.type) {
    case 'request':
      return { ...state, isloading: true, error: null, response: null };
    case 'receive':
      return { ...state, isloading: false, error: null, response: action.data };
    case 'error':
      return { ...state, isloading: false, error: action.data, response: null };
    default:
      throw new Error();
  }
}

export function useAppFetch() {
  const [state, dispatch] = useReducer(appReducer, initialState);

  function fetchApp(accessToken, appId) {
    dispatch({ type: 'request', data: null });
    axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      url: `${SOLUTION_URL}/v1/solutions/${appId}`
    })
      .then(res => {
        dispatch({ type: 'receive', data: res.data });
      })
      .catch(error => {
        dispatch({
          type: 'error',
          data: (error && error.response && error.response.data) || {
            error: 'Failed to fetch app data'
          }
        });
      });
  }
  return [
    state.isloading,
    state.isRequestFulfilled,
    state.error,
    state.response,
    fetchApp
  ];
}

export function useAppMetricsFetch() {
  const [state, dispatch] = useReducer(metricsReducer, initialState);

  function fetchMetrics(appId, accessToken, filters) {
    dispatch({ type: 'request', data: null });
    const qs = buildQueryString({
      expand: true,
      timeRange: filters.timeRange,
      interval: getIntervalFromTimeRange(filters.timeRange),
      apiId: filters.apiId === ALL_APIS_OPTION.value ? undefined : filters.apiId
    });
    axios({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      url: `${config.api.metricsUrl}/metrics/apps/${appId}?${qs}`
    })
      .then(res => {
        dispatch({
          type: 'receive',
          data: appendMaxForAppMetrics(res.data)
        });
      })
      .catch(error => {
        dispatch({
          type: 'error',
          data: (error && error.response && error.response.data) || {
            error: 'Failed to fetch app metrics'
          }
        });
      });
  }
  return [
    state.isloading,
    state.isRequestFulfilled,
    state.error,
    state.response,
    fetchMetrics
  ];
}

export function useAsyncAppApiDataFetch() {
  const [state, dispatch] = useReducer(metricsReducer, initialState);

  function fetchMetrics(accessToken, filters) {
    dispatch({ type: 'request', data: null });
    const reqBody = {
      select: [
        'count(messageSize)',
        'apiId',
        'appId',
        'appName',
        'status',
        'operation'
      ],
      groupBy: ['apiId', 'appId', 'status'],
      filter: [
        {
          attribute: 'apiId',
          operator: 'eq',
          value: [filters.apiId]
        }
      ],
      timeRange: filters.timeRange,
      persona: 'apiPublisher'
    };

    if (!!filters.appId && filters.appId !== 'allApp') {
      reqBody.filter.push({
        operator: 'eq',
        attribute: 'appId',
        value: [filters.appId]
      });
    }

    axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      url: `${config.api.metricsUrl}/metrics/events`,
      data: reqBody
    })
      .then(response => {
        const events = [];
        response.data.result.attributes.forEach(data => {
          let event = {};
          data.metrics.forEach(data => {
            event = { ...event, [data.name]: data.value };
          });
          events.push(event);
        });
        dispatch({
          type: 'receive',
          data: events
        });
      })
      .catch(error => {
        dispatch({
          type: 'error',
          data: (error && error.response && error.response.data) || {
            error: 'Failed to fetch Async API events metrics'
          }
        });
      });
  }
  return [
    state.isloading,
    state.isRequestFulfilled,
    state.error,
    state.response,
    fetchMetrics
  ];
}
