import React, { useEffect, useState } from 'react';
import {
  CardWrapper,
  CardBody,
  CardContent,
  CardFooter,
  CardHeader
} from 'cdk-radial';
import { CircularProgress } from '@rmwc/circular-progress';

export const AsyncApiCountCard = ({
  type,
  isLoading,
  isFulfilled,
  isError,
  asyncCounts,
  timeRange
}) => {
  const getEventsCountClassname = length => {
    if (length < 7) return 'large-text';
    if (length < 10) return 'med-text';
    return 'small-text';
  };
  const getTitleHeader = type => {
    if (type === 'published') {
      return <CardHeader title={'Published Events'}></CardHeader>;
    } else if (type === 'success') {
      return <CardHeader title={'Successful Delivery Attempts'}></CardHeader>;
    } else if (type === 'failure') {
      return <CardHeader title={'Failed Delivery Attempts'}></CardHeader>;
    }
  };

  const getEvent = type => {
    if (type === 'published') {
      return (
        <div
          className={`events-metrics-data ${getEventsCountClassname(
            asyncCounts.published.toString().length
          )}`}
        >
          <span>{asyncCounts.published}</span>
        </div>
      );
    } else if (type === 'success') {
      return (
        <div
          className={`events-metrics-data ${getEventsCountClassname(
            asyncCounts.success.toString().length
          )}`}
        >
          <span>{asyncCounts.success}</span>
        </div>
      );
    } else if (type === 'failure') {
      return (
        <div
          className={`events-metrics-data ${getEventsCountClassname(
            asyncCounts.failure.toString().length
          )}`}
        >
          <span>{asyncCounts.failure}</span>
        </div>
      );
    }
  };

  const getText = type => {
    if (type === 'published') {
      return 'Published Events';
    } else if (type === 'success') {
      return 'Successful Events';
    } else if (type === 'failure') {
      return 'Failed Events';
    }
  };

  return (
    <>
      {isLoading && (
        <CardWrapper className="metric-card">
          <div className="fdn-metric--empty-space">
            <CircularProgress />
          </div>
          <CardBody>
            <CardContent></CardContent>
            <CardFooter />
          </CardBody>
        </CardWrapper>
      )}
      {isError && (
        <CardWrapper className="metric-card">
          <div className="fdn-metric--empty-space">
            Sorry, we are unable to load events metrics at this time. Please try
            again later.
          </div>
          <CardBody>
            <CardContent></CardContent>
            <CardFooter />
          </CardBody>
        </CardWrapper>
      )}
      {isFulfilled && (
        <CardWrapper className="events-metrics-card">
          <CardBody>
            {getTitleHeader(type)}
            <CardContent>
              {getEvent(type)}
              <div style={{ fontSize: '12px', fontStyle: 'italic' }}>
                {getText(type)} in the past {timeRange}
              </div>
            </CardContent>
          </CardBody>
        </CardWrapper>
      )}
    </>
  );
};
