import React, { Component, Fragment } from 'react';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
// Components
import { Flex, FlexItem } from '@fortellis/flex';
import { Subtitle1 } from '@fortellis/typography';
import { CircularProgress } from '@rmwc/circular-progress';
// Styles
import './authLoader.scss';
// Variables
const LOADING_MESSAGE = 'Standby while we rearrange a bunch of 1s and 0s';

class AuthLoader extends Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      message: ''
    };
  }

  componentDidMount() {
    this.mounted = true;
    setTimeout(() => {
      this.mountedSetState({ message: LOADING_MESSAGE });
    }, 1000);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mountedSetState = update => {
    if (this.mounted) {
      this.setState(update);
    }
  };

  render() {
    if (this.props.auth.isAuthenticated) {
      return null;
    }

    return (
      <Flex
        className="auth-loader"
        align="center"
        justify="center"
        direction="column"
      >
        <FlexItem>
          <Subtitle1>{this.state.message}</Subtitle1>
        </FlexItem>
        <FlexItem>
          <CircularProgress />
        </FlexItem>
      </Flex>
    );
  }
}

export const AuthLoaderWithAuth = withAuth(AuthLoader);

export function withAuthLoader(WrappedComponent) {
  return function ComponentWithAuthLoader(props) {
    return (
      <Fragment>
        <AuthLoaderWithAuth />
        <WrappedComponent {...props} />
      </Fragment>
    );
  };
}

export default AuthLoader;
