import { useMemo } from 'react';
import { CardWrapper, CardBody, CardContent } from 'cdk-radial';

export const AppAsyncApiMetricCount = ({ isLoading, metricRecord }) => {
  const [successCount, failCount] = useMemo(() => {
    if (Array.isArray(metricRecord) && metricRecord.length > 0) {
      const sCount = metricRecord
        .filter(f => f.status === 'success')
        .map(m => m.count)
        .reduce((b, c) => parseFloat(b) + parseFloat(c), 0);
      const fCount = metricRecord
        .filter(f => f.status === 'failure')
        .map(m => m.count)
        .reduce((b, c) => parseFloat(b) + parseFloat(c), 0);
      return [sCount, fCount];
    }
    return [0, 0];
  }, [metricRecord]);

  return (
    <>
      {!isLoading && (
        <CardWrapper className="bar-chart-metric-card1">
          <CardBody>
            <CardContent>
              <div className="app-async-metric-count-header-wrapper">
                <div className="app-async-metric-header">
                  Attempted Broadcast
                </div>
                <div className="app-async-metric-header">
                  Successful Deliveries
                </div>
                <div className="app-async-metric-header app-async-metric-failed-delivery">
                  Failed Deliveries
                </div>
              </div>
              <hr />
              {metricRecord && !!metricRecord.length && (
                <div className="app-async-metric-count-wrapper">
                  <div className="app-async-metric-value">
                    {successCount + failCount}
                  </div>
                  <div className="app-async-metric-value">{successCount}</div>
                  <div className="app-async-metric-value">{failCount}</div>
                </div>
              )}
            </CardContent>
            {/* <CardFooter /> */}
          </CardBody>
        </CardWrapper>
      )}
    </>
  );
};
