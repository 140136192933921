/**
 * Configs are populated from .env files in the root directory
 * https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#adding-custom-environment-variables
 */
import { openAha } from '@cdk-prod/fortellis-aha-widget';

const isAhaEnabled =
  process.env.REACT_APP_FEATURE_AHA_WIDGET_ENABLED === 'true';

const isCaseManagementEnabled =
  process.env.REACT_APP_NAV_CASE_MANAGEMENT_ENABLED === 'true';

export default {
  env: process.env['REACT_APP_ENV'],
  captchaSiteKey: process.env['REACT_APP_CAPTCHA_SITE_KEY'],
  api: {
    developerUrl: process.env['REACT_APP_API_DEVELOPER_URL'],
    domainUrl: process.env['REACT_APP_API_DOMAIN_URL'],
    organizationUrl: process.env['REACT_APP_API_ORGANIZATION_URL'],
    organizationUrlV2: process.env['REACT_APP_API_ORGANIZATION_URL_V2'],
    permissionURL: process.env['REACT_APP_API_PERMISSION_URL'],
    invitationUrl: process.env['REACT_APP_API_INVITATION_URL'],
    eventingControlUrl: process.env['REACT_APP_API_EVENTING_CONTROL_URL'],
    providerUrl: process.env['REACT_APP_API_PROVIDER_URL'],
    specUrl: process.env['REACT_APP_API_SPEC_URL'],
    apiUrl: process.env['REACT_APP_API_URL'],
    subscriptionUrl: process.env['REACT_APP_API_SUBSCRIPTION_URL'],
    billingUrl: process.env['REACT_APP_API_BILLING_URL'],
    solutionUrl: process.env['REACT_APP_SOLUTION_URL'],
    accountsServerUrl: process.env['REACT_APP_ACCOUNTS_SERVER_URL'],
    accountsUiURL: process.env['REACT_APP_ACCOUNTS_UI_URL'],
    redlineContractUrl: process.env['REACT_APP_REDLINE_CONTRACT_URL'],
    apiGatewayUrl: process.env['REACT_APP_API_GATEWAY_URL'],
    apiGatewayUrlV2: process.env['REACT_APP_API_GATEWAY_URL_V2'],
    apiGatewayBaseUrl: process.env['REACT_APP_API_GATEWAY_BASE_URL'],
    apiGatewayDirectoryBaseUrl:
      process.env['REACT_APP_API_GATEWAY_DIRECTORY_URL'],
    asyncApiServiceBaseUrl: process.env['REACT_APP_ASYNC_API_SERVICE_BASE_URL'],
    fortellisApiDomain: process.env['REACT_APP_FORTELLIS_API_DOMAIN'],
    apiEsignUrl: process.env['REACT_APP_ESIGN_URL'],
    metricsUrl: process.env['REACT_APP_METRICS_SERVICE_URL'],
    metricServiceUrl: process.env['REACT_APP_METRICS_SERVICE_URL_V2'],
    apigeeHostUrl: process.env['REACT_APP_APIGEE_HOST'],
    brokerUrl: process.env['REACT_APP_BROKER_URL'],
    marketplaceServiceUrl: process.env['REACT_APP_MARKETPLACE_SERVICE_URL'],
    paymentsServiceUrl: process.env['REACT_APP_PAYMENT_SERVICE_URL'],
    salesforceThreePAUrl: process.env['REACT_APP_API_SALESFORCE_3PA_URL'],
    utilizationServiceUrl: process.env['REACT_APP_UTILIZATION_SERVICE_URL']
  },
  launchDarkly: {
    clientSideId: process.env['REACT_APP_LD_CLIENT_SIDE_ID']
  },
  docs: {
    adminApi: `${process.env['REACT_APP_NAV_DOCS_URL']}/docs/tutorials/admin-api/overview/`,
    specs: `${process.env['REACT_APP_NAV_DOCS_URL']}/docs/tutorials/spec-provider/api-spec-introduction/`,
    implementations: `${process.env['REACT_APP_NAV_DOCS_URL']}/docs/tutorials/overview/overview/`,
    automaticConnections: `${process.env['REACT_APP_NAV_DOCS_URL']}/docs/tutorials/admin-api/no-admin-api`,
    manualConnections: `${process.env['REACT_APP_NAV_DOCS_URL']}/docs/tutorials/connection-management/overview/`,
    apiDocs: `${process.env['REACT_APP_NAV_DOCS_URL']}/docs/tutorials/configuration/api-registration`
  },
  apidocs: {
    url: process.env['REACT_APP_NAV_DEVELOPER_APIS_URL']
  },
  blog: {
    text: 'Fortellis Developer',
    url: process.env['REACT_APP_BLOG_URL']
  },
  logout: {
    url: process.env['REACT_APP_LOGOUT_URL']
  },
  oauth: {
    baseUrl: process.env['REACT_APP_OAUTH_BASE_URL'],
    clientId: process.env['REACT_APP_OAUTH_CLIENT_ID'],
    responseType: process.env['REACT_APP_OAUTH_RESPONSE_TYPE'],
    scope: process.env['REACT_APP_OAUTH_SCOPE']
  },
  signup: {
    url: process.env['REACT_APP_SIGNUP_URL']
  },
  personalAccount: {
    joinOrgUrl: process.env['REACT_APP_JOIN_ORG_URL']
  },
  specs: {
    designGuidelinesUrl: (process.env['REACT_APP_NAV_DOCS_URL'] || '').replace(/\/$/, '') + '/docs/tutorials/spec-provider/api-design-guidelines/', // prettier-ignore
    specPublishingRuleUrl:
      (process.env['REACT_APP_NAV_DOCS_URL'] || '').replace(/\/$/, '') +
      '/docs/tutorials/spec-writing/spec-publishing-rules/'
  },
  contact: {
    url: process.env['REACT_APP_CONTACT_US_URL']
  },
  support: {
    // for links that take you directly into sending an email to support
    url: 'mailto:support@fortellis.io'
  },
  consent: {
    coordinate: { page: 6, x: 82, y: 714 }
  },
  nav: {
    home: {
      text: 'Fortellis',
      url: process.env['REACT_APP_NAV_HOME_URL']
    },
    apis: {
      text: 'APIs',
      url: process.env['REACT_APP_NAV_DEVELOPER_APIS_URL']
    },
    marketplace: {
      text: 'Marketplace',
      url: process.env['REACT_APP_NAV_MARKETPLACE_URL']
    },
    insights: {
      text: 'Insights',
      url: process.env['REACT_APP_NAV_DEVELOPER_INSIGHTS_URL']
    },
    community: {
      text: 'Community',
      url: process.env['REACT_APP_NAV_DEVELOPER_COMMUNITY_URL']
    },
    docs: {
      text: 'Resources',
      url: process.env['REACT_APP_NAV_DOCS_URL']
    },
    user: {
      text: 'User',
      menu: [
        {
          code: 'org-management',
          text: 'Organization Management',
          url: process.env['REACT_APP_NAV_USER_ORG_MANAGEMENT_URL']
        },
        {
          code: 'developer-account',
          text: 'Developer Account',
          url: process.env['REACT_APP_NAV_USER_DEVELOPER_ACCOUNT_URL']
        },
        {
          code: 'marketplace-account',
          text: 'Marketplace Account',
          menu: [
            {
              code: 'marketplace-account-solutions',
              text: 'Apps',
              url: process.env['REACT_APP_NAV_USER_MARKETPLACE_ACCOUNT_SOLUTION_URL'] // prettier-ignore
            }
          ]
        },
        {
          code: 'divider-desktop',
          text: 'Divider Desktop'
        },
        {
          code: 'settings',
          text: 'Settings',
          url: process.env['REACT_APP_NAV_USER_SETTINGS_URL']
        },
        ...(isAhaEnabled
          ? [
              {
                code: 'submit-ideas',
                text: 'Submit Ideas',
                handleMenuClick: () => {
                  const campaignId =
                    process.env.REACT_APP_PLATFORM_CAMPAIGNID_FORTELLIS;
                  openAha(campaignId, isAhaEnabled);
                }
              }
            ]
          : []),
        ...(isCaseManagementEnabled
          ? [
              {
                code: 'case-management',
                text: 'Submit/Review a Case',
                handleMenuClick: () => {
                  window.open(
                    process.env['REACT_APP_NAV_CASE_MANAGEMENT_URL'],
                    '_blank'
                  );
                }
              }
            ]
          : [])
      ]
    },
    language: {
      text: 'Language',
      menu: [
        { code: 'en', text: 'English - EN' },
        { code: 'fr', text: 'Français - FR' }
      ]
    }
  },
  footer: {
    social: [
      {
        code: 'facebook',
        url: 'https://www.facebook.com/groups/551215795213352/?source_id=180950569178220' // prettier-ignore
      },
      {
        code: 'twitter',
        url: 'https://twitter.com/fortellisdev'
      },
      {
        code: 'github',
        url: 'https://github.com/fortellis'
      },
      {
        code: 'youtube',
        url: 'https://www.youtube.com/channel/UCk4etySO7-MJhYCPNDtox2A'
      }
    ],
    nav: [
      {
        text: 'About',
        menu: [
          {
            text: 'Community',
            url: process.env.REACT_APP_NAV_DEVELOPER_COMMUNITY_URL
          },
          {
            text: 'Marketplace',
            url: process.env.REACT_APP_NAV_MARKETPLACE_URL
          }
        ]
      },
      {
        text: 'Documentation',
        menu: [
          {
            text: 'API Directory',
            url: process.env.REACT_APP_NAV_DEVELOPER_APIS_URL
          },
          {
            text: 'Resources',
            url: process.env.REACT_APP_NAV_DOCS_URL
          }
        ]
      },
      {
        text: 'Support',
        menu: [
          {
            text: 'Contact Us',
            url: process.env.REACT_APP_CONTACT_US_URL
          },
          {
            text: 'Developer Forum',
            url: process.env.REACT_APP_BLOG_URL
          }
        ]
      }
    ],
    copyright: {
      trademark: `©${new Date().getFullYear()}. Fortellis is a registered trademark of CDK Global, LLC.`,
      legal: {
        text: 'Privacy & Legal',
        url: process.env['REACT_APP_LEGAL_URL']
      },
      terms: {
        text: 'Terms of Use & Privacy Policy',
        url: process.env['REACT_APP_TERMS_URL']
      }
    }
  },
  feature: {
    isEventingSolution: process.env['REACT_APP_FEATURE_IS_EVENTING_SOLUTION']
  },
  listingOnly: {
    dummyApi: process.env['REACT_APP_LISTING_ONLY_DUMMY_API']
  },
  consentDocForInternalApiIntegrations: {
    url: process.env['REACT_APP_CONSENT_DOC_API_OWNER']
  },
  cppDoc: {
    url: process.env['REACT_APP_CPP_DOC']
  },
  vsDoc: {
    url: process.env['REACT_APP_VS_DOC']
  }
};
