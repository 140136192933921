import React, { useState, useEffect } from 'react';
import { Flex } from '@fortellis/flex';
import { CircularProgress } from '@fortellis/circular-progress';

/**
 *
 * @param {Object} props
 * @param {number} props.delay - Delay in milliseconds before loading indicator is shown
 * @param {*} props.content - Loading indicator to be shown, defaulted to CircularProgress
 */
const DelayedLoadingSection = ({
  delay = 0,
  content = <CircularProgress />
}) => {
  const [isDelayDone, setIsDelayDone] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setIsDelayDone(true), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return (
    <div>
      <Flex align="center" justify="center" style={{ minHeight: '100vh' }}>
        {isDelayDone ? content : null}
      </Flex>
    </div>
  );
};

export default DelayedLoadingSection;
