import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withEntityContext } from '@cdk-prod/fortellis-entity-context';
import { Header } from '@cdk-prod/fortellis-header';
import { LocaleConsumer } from '@fortellis/locale';

import config from '../../config/config';
import './header.scss';

function AuthHeader({ auth, entityContext, location, mobile, ...props }) {
  const classes = classnames({
    'frc-header--cover': location.pathname === '/'
  });

  return (
    <LocaleConsumer>
      {localeContext => (
        <Header
          className={classes}
          mobile={mobile}
          authenticated={auth.isAuthenticated}
          entityContext={entityContext}
          localeContext={localeContext}
          login={auth.login}
          logout={() => {
            localStorage.removeItem('DNPBackToOrgManagementLink');
            auth.logout();
          }}
          nav={config.nav}
          routerLink={Link}
          selectedKey="developer"
          signup={config.signup.url}
          user={auth.userData}
        />
      )}
    </LocaleConsumer>
  );
}

AuthHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withAuth(withEntityContext(AuthHeader)));
