import React from 'react';
import { Grid, Column, Row } from 'cdk-radial';
import { DropdownMenu, IconRefresh, PrimaryButton } from 'cdk-radial';
import {
  ENVIRONMENTS,
  TIME_SPANS,
  getEnvOption,
  getimeSpanOption,
  TIME_SPANS_ASYNC_API
} from './utils';
import { apiTypes } from '../developer-account/api/constants';
import { ApiIntegratedAppParam } from '../../components/developer-account/api/api-details/api-error/ApiIntegratedAppParam';

const getVersionOption = version => ({ label: version, value: version });

export default function ApiMetricsInput({
  refresh,
  triggerRefresh,
  filters,
  onFilterChange,
  availableVersions,
  isLoading,
  integratedApps,
  apiType
}) {
  return (
    <Grid className="api-visualization">
      <Row style={{ paddingBottom: '20px' }}>
        {apiType !== apiTypes.ASYNC && (
          <Column l={1.5} style={{ marginRight: '20px' }}>
            <DropdownMenu
              inputId="api-env"
              label="Environment"
              value={getEnvOption(filters.environment)}
              onChange={e => {
                onFilterChange({ environment: e.value });
              }}
              options={ENVIRONMENTS}
            />
          </Column>
        )}
        {apiType !== apiTypes.ASYNC && (
          <Column l={1.5} style={{ marginRight: '20px' }}>
            <DropdownMenu
              inputId="api-version"
              label="Version"
              value={getVersionOption(filters.apiInstanceVersion)}
              onChange={e => {
                onFilterChange({ apiInstanceVersion: e.value });
              }}
              options={availableVersions.map(getVersionOption)}
              isDisabled={!availableVersions.length}
            />
          </Column>
        )}
        <Column l={1.5} style={{ marginRight: '20px' }}>
          <DropdownMenu
            inputId="time-span"
            label="Time Span"
            value={getimeSpanOption(filters.timeRange, apiType)}
            onChange={e => {
              onFilterChange({ timeRange: e.value });
            }}
            options={
              apiType !== apiTypes.ASYNC ? TIME_SPANS : TIME_SPANS_ASYNC_API
            }
            isDisabled={apiType !== apiTypes.ASYNC && !availableVersions.length}
          />
        </Column>
        <Column l={4.6} style={{ marginRight: '20px' }}>
          <ApiIntegratedAppParam
            integratedAppList={integratedApps}
            environment={
              apiType !== apiTypes.ASYNC ? filters.environment : 'prod'
            }
            onAppIdSelection={appId => onFilterChange({ appId: appId })}
          />
        </Column>
        <Column m={1} s={12} xs={12} style={{ marginRight: '20px' }}>
          <PrimaryButton
            style={{ marginTop: '4px' }}
            dataTestId="primary-button"
            icon={<IconRefresh />}
            iconColor="primary"
            onClick={function() {
              triggerRefresh(!refresh);
            }}
            text="Refresh"
            size="large"
            isDisabled={
              isLoading ||
              (apiType !== apiTypes.ASYNC && !availableVersions.length)
            }
          />
        </Column>
      </Row>
    </Grid>
  );
}
