import React from 'react';
import { IconOpenInNew } from 'cdk-radial';
import './learnMore.scss';
const LearnMore = ({ url }) => {
  return (
    <a
      target="_blank"
      className="fdn-api__anchor_tag"
      href={url}
      rel="noopener noreferrer"
    >
      <span className="fdn-api__learnmore">Learn More</span>
      <IconOpenInNew className="fdn-api__open_in_new" />
    </a>
  );
};
export default LearnMore;
