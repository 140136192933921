const info = {
  REVERT_TO_PRIVATE: {
    message:
      'Your API is PUBLIC. Reverting to private will remove it from the API Directory. It will still be visible and available to anyone already using it in a published application. It will always be available to anyone in your organization.',
    buttonText: ' Revert To Private ',
    iconClass: 'fdn-api__info_icon_yellow',
    disabled: false,
    gridSize: [8, 3, 1],
    buttonClass: 'fdn-api__panel-card-button fdn-api__panel-card-button-margin'
  },
  ENABLED_FOR_PUBLIC: {
    message:
      'This API is currently private. You can share the API with individual organizations or make it globally available in API Directory by making it public. Users of the private API are unaffected by an API being made public.',
    buttonText: 'Make Public',
    iconClass: 'fdn-api__info_icon_blue',
    disabled: false,
    gridSize: [9, 2, 2],
    buttonClass: 'fdn-api__panel-card-button'
  },
  ORGANISATION_UNVERIFIED: {
    message:
      'Your organization is in the process of being verified. Once verified you will be able to make this API public. Please try again later or',
    buttonText: 'Make Public',
    iconClass: 'fdn-api__info_icon_red',
    disabled: true,
    gridSize: [9, 2],
    buttonClass: 'fdn-api__panel-card-button',
    unverifiedOrg: true
  },
  DISABLED_FOR_PUBLIC: {
    message:
      'This API is private and available to use in your organization. If you choose to make it public on the API Directory you must complete the information above.',
    buttonText: 'Make Public',
    iconClass: 'fdn-api__info_icon_blue',
    disabled: true,
    gridSize: [9, 2],
    buttonClass: 'fdn-api__panel-card-button'
  },
  NEW_DISABLED_FOR_PUBLIC: {
    message:
      'This API is private and by default only members of the organization where the API is registered have access to the API. Complete the required listing details above to share the API with a larger audience.',
    buttonText: 'Submit',
    iconClass: 'fdn-api__info_icon_blue',
    disabled: true,
    gridSize: [9, 2],
    buttonClass: 'fdn-api__panel-card-button'
  },
  ENABLE_FOR_ADMIN_APPROVAL: {
    message:
      'This API is currently private and members of the organization containing the API can access the API. You can share the API with individual organizations, or make it public and publish it globally in API Directory. Click the Submit button to begin the admin review process. Once approved by Fortellis, you can make the API public.',
    buttonText: 'Submit',
    iconClass: 'fdn-api__info_icon_blue',
    disabled: false,
    gridSize: [9, 2, 2],
    buttonClass: 'fdn-api__panel-card-button'
  },
  DIABLE_FOR_ADMIN_APPROVAL_PENDING: {
    message:
      'This API is currently private while being reviewed. You can share the API with individual organizations and once approved you can make the API public.',
    buttonText: 'Make Public',
    iconClass: 'fdn-api__info_icon_blue',
    disabled: true,
    gridSize: [7, 2, 2],
    buttonClass: 'fdn-api__panel-card-button'
  }
};

const testSubscription = {
  pending: 'Retry Activation',
  active: 'Deactivate Test Subscription',
  inactive: 'Activate Test Subscription',
  declined: 'Retry Activation'
};

const activationTypes = {
  AUTO: 'auto',
  MANUAL: 'manual',
  ADMIN_API: 'admin_api'
};

const activationTypeLabels = {
  [activationTypes.AUTO]: 'Automatic',
  [activationTypes.MANUAL]: 'Manual',
  [activationTypes.ADMIN_API]: 'Admin API'
};

const apiStateMap = {
  modifying: 'provisioning',
  provisioning: 'provisioning',
  error: 'error',
  creating: 'provisioning',
  // 'deleting' produces a different status display, but it should still have
  // all the same effects on the UI as provisioning (edit/delete button lockout,
  // auto refresh every 10 seconds)
  deleting: 'provisioning',
  ready: 'ready',
  rejected: 'error'
};

const apiStrings = {
  PROVISIONING: 'provisioning',
  ERROR: 'error',
  READY: 'ready'
};

const shareApi = {
  message:
    'Organizations invited to your API can view the API page and make API requests even if the API is private.'
};

const apiTypes = {
  REST: 'rest-api',
  ASYNC: 'async-api'
};

const schemaVersionStatusTypes = {
  DRAFT: 'draft',
  PUBLISHED: 'published'
};

const testAppNameSuffix = '-testing-app';
const testAppNameSuffixLegacy = '---implementation-testing-solution';

const CONFIRM = 'confirm';

const COPIED = 'Copied To Clipboard!';

const fileUploadSizeLimit = 4 * 1024 * 1024;
const fileUploadSizeErrorMessage =
  'File is too large. The maximum size for file upload is 4 MB.';

const accessGrantStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

const privacyProtectionHints = {
  DURING_CREATE:
    'Checking this indicates this API contains Consumer Privacy Protection functionality that will be surfaced during the subscription process where the dealer can opt in or out.',
  ON_POST_CREATE:
    'This API contains Consumer Privacy Protection functionality that will be surfaced during the subscription process where the dealer can opt in or out. To change this, contact Fortellis Support or create a new API.',
  PRIVACY_PROTECTION_WARNING:
    'You have enabled Consumer Privacy Protection. This policy is surfaced during the API subscription process where the dealer can opt in or out of the program.'
};

const verticalScopingHints = {
  DURING_CREATE:
    'Check this to allow Field Management functionality that will permit the App publisher to select required and optional fields within the API.',
  ON_POST_CREATE:
    'This API has Field Management enabled and allows App Developers to identify required API Fields for their App to function.',
  VERTICAL_SCOPING_WARNING:
    'You have enabled API Field Management. This permits the dealer to configure required and optional API fields being integrated into the App.'
};

const ERRORS = {
  ACCESS_GRANTS_LOADING_FAILED: 'Error in loading Access Grants'
};

export {
  apiStateMap,
  activationTypes,
  activationTypeLabels,
  testSubscription,
  info,
  apiStrings,
  shareApi,
  apiTypes,
  schemaVersionStatusTypes,
  testAppNameSuffix,
  testAppNameSuffixLegacy,
  CONFIRM,
  COPIED,
  fileUploadSizeLimit,
  fileUploadSizeErrorMessage,
  accessGrantStatuses,
  privacyProtectionHints,
  verticalScopingHints,
  ERRORS
};
