import { UtilizationPanel } from '@cdk-prod/fortellis-app-utilization-component';
import { TextButton } from '@cdk-rds/button';
import { IconChevronLeft } from '@cdk-rds/icons';
import { Page, PageContent } from '@fortellis/page';
import React, { useContext, useEffect, useState } from 'react';
import { fortellisTheme, Heading } from 'cdk-radial';
import config from '../../../../config/config';
import EntityContext from '@cdk-prod/fortellis-entity-context';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import axios from 'axios';
import moment from 'moment';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import localeData from '@cdk-prod/fortellis-app-utilization-component/src/locales/data.json';
import ProgressSpinner from '../../../loading/ProgressSpinner';
import { useLocation, useParams } from 'react-router-dom';

const locale = 'en';
const messages = localeData['en'];

const UtilizationPage = ({ auth }) => {
  const [solutions, setSolutions] = useState([]);
  const [utilizationByAPI, setUtilizationByAPI] = useState([]);
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const startDate = moment()
    .startOf('month')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');

  const endDate = moment()
    .startOf('month')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');

  const { id: solutionId } = useParams();

  const entity = useContext(EntityContext);

  const fetchSolutions = async solutionId => {
    try {
      const response = await axios.get(
        `${config.api.solutionUrl}/v1/solutions/${solutionId}`,
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`
          }
        }
      );
      const { data } = response;
      const solutionData = {
        id: data.id,
        overview: { name: data.name }
      };
      const solutionDataArray = [solutionData];
      return solutionDataArray;
    } catch (error) {
      console.error('fetch solutions is not successful', error);
    }
  };

  const getUtilizationData = async ({ solutionId, startDate, endDate }) => {
    try {
      const appId = solutionId;
      const response = await axios.get(
        `${config.api.utilizationServiceUrl}/v1/apps/${appId}/utilization`,
        {
          params: {
            appId,
            startDate: moment(startDate)
              .startOf('day')
              .format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDate)
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
          },
          headers: {
            Authorization: `Bearer ${auth.accessToken}`
          }
        }
      );
      const utilizationByAPI = response.data.items;
      const res = {
        data: { getUtilization: { utilizationByAPI: utilizationByAPI } }
      };
      return res;
    } catch (error) {
      console.error('Error fetching utilization data:', error);
    }
  };

  const fetchData = async () => {
    try {
      const [solutionResData, utilizationResData] = await Promise.all([
        fetchSolutions(solutionId),
        getUtilizationData({ solutionId, startDate, endDate })
      ]);
      setSolutions(solutionResData);
      setUtilizationByAPI(
        utilizationResData?.data?.getUtilization?.utilizationByAPI
      );
    } catch (error) {
      console.error('Error fetching solution data and utilization data', error);
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    if (auth.accessToken) {
      fetchData();
    }
  }, [auth.accessToken]);

  if (pageIsLoading) {
    return <ProgressSpinner fullScreen message={'Loading...'} />;
  }

  return (
    <>
      <div className="fdn-back-button">
        <div className="fdn-back-button-content">
          <TextButton
            href={`/developer-account/solutions/view/${solutionId}`}
            icon={<IconChevronLeft />}
            text="Back"
          />
        </div>
      </div>
      <Page>
        <PageContent>
          <div>
            <Heading headingType="heading-1" level={1}>
              Utilization
            </Heading>
            <br />
            <IntlProvider locale={locale} messages={messages}>
              <ThemeProvider theme={fortellisTheme}>
                <UtilizationPanel
                  solutionId={solutionId}
                  utilizationByAPI={utilizationByAPI}
                  getUtilizationData={getUtilizationData}
                  startDate={startDate}
                  endDate={endDate}
                  entity={entity}
                  solutions={solutions}
                />
              </ThemeProvider>
            </IntlProvider>
          </div>
        </PageContent>
      </Page>
    </>
  );
};

export default withAuth(UtilizationPage);
