import React, { useState } from 'react';
import { getISOFormattedUTCDateString } from './utils';

import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  Crosshair,
  GradientDefs,
  DiscreteColorLegend
} from 'react-vis';
import 'react-vis/dist/style.css'; // IMP: We need to import react-vis css

function parseMetricsData(multiLineData, config) {
  return multiLineData.map((eachLineData, lineDataIndex) => {
    return eachLineData.values.map(each => {
      const lineConfig = getLineConfig(
        multiLineData[lineDataIndex].name,
        config
      );
      return {
        x: new Date(getISOFormattedUTCDateString(each.time)).getTime(),
        y: parseInt(each.value),
        label: eachLineData[config?.chart?.labelProperty] || lineConfig.label,
        hoverText:
          eachLineData[config?.chart?.labelProperty] ||
          lineConfig.hoverText ||
          lineConfig.label
      };
    });
  });
}

function getLineConfig(label, config) {
  let lineConfig =
    config.chart && config.chart.lineConfig && config.chart.lineConfig[label];
  if (!lineConfig) {
    lineConfig = { stroke: '#000000', label: 'Not Available' };
  }
  return lineConfig;
}

function getLineStyle(label, config) {
  let style = { strokeWidth: 2 };
  const lineConfig =
    (config.chart &&
      config.chart.lineConfig &&
      config.chart.lineConfig[label]) ||
    '';
  if (lineConfig) {
    if (lineConfig.stroke) {
      style.stroke = lineConfig.stroke;
    }
  }
  return style;
}

function formatCrosshairTitle(values) {
  return {
    title: `Time`,
    value: getLocalDateTimeFromEpoch(values[0].x)
  };
}

function padInteger(n) {
  return n.toString().padStart('2', 0);
}

const getLocalDateTimeFromEpoch = epoch => {
  const date = new Date(epoch);
  return `${padInteger(date.getMonth() + 1)}/${padInteger(
    date.getDate()
  )} ${padInteger(date.getHours())}:${padInteger(
    date.getMinutes()
  )}:${padInteger(date.getSeconds())}`;
};

function getLegendConfig(config, data) {
  const labels = data.map(each => each.name);
  return labels.map(each => ({
    title: config.chart.labelProperty
      ? each[config.chart.labelProperty]
      : config.chart.lineConfig[each].label || 'Title Missing',
    color: config.chart.lineConfig[each].stroke
  }));
}

export function LineSeriesChartLegend({ config, data }) {
  return (
    <div className="display-inline">
      <svg height={0} width={0}>
        <GradientDefs>
          <pattern
            id="stripes"
            width="4"
            height="4"
            patternUnits="userSpaceOnUse"
          >
            <path d="M 0, 0 l 5, 5" stroke="#45aeb1" strokeLinecap="square" />
          </pattern>
          <pattern
            id="circles"
            width="15"
            height="15"
            patternUnits="userSpaceOnUse"
          >
            <circle cx="1.5" cy="1.5" r="0.8" fill="magenta" />
          </pattern>
        </GradientDefs>
      </svg>
      <DiscreteColorLegend
        orientation="horizontal"
        width={150}
        items={getLegendConfig(config, data)}
        className="display-inline"
      />
    </div>
  );
}

export function LineSeriesChart({ config, data }) {
  const [crosshairValues, setCrosshairValues] = useState([]);
  const multiLineData = parseMetricsData(data || [], config);
  const {
    chart: { yAxisTickFormat, crossHairValueFormatter }
  } = config;

  function formatCrosshairItems(values) {
    return values.map((v, i) => {
      return {
        title: v.hoverText,
        value: (crossHairValueFormatter && crossHairValueFormatter(v.y)) || v.y
      };
    });
  }

  return (
    <div>
      <XYPlot
        xType="time"
        height={config.chart.height}
        width={config.chart.width}
        yDomain={config.chart.yAxisCounterRange}
        onMouseLeave={() => {
          setCrosshairValues([]);
        }}
      >
        {config.chart.showHorizontalGridLines !== false && (
          <HorizontalGridLines />
        )}
        {config.chart.showXAxis !== false && (
          <XAxis tickTotal={config.chart.xAxisTicksTotal} />
        )}
        {config.chart.showYAxis !== false && (
          <YAxis
            tickTotal={config.chart.yAxisTicksTotal}
            tickFormat={yAxisTickFormat}
          />
        )}
        {/* <YAxis title="Milliseconds" tickValues={[100, 200, 300, 400, 500, 1000, 2000]}/> */}
        {config.chart.showCrossHairs !== false && (
          <Crosshair
            itemsFormat={formatCrosshairItems}
            titleFormat={formatCrosshairTitle}
            values={crosshairValues}
          />
        )}
        {multiLineData.map((eachLineData, index) => {
          return (
            <LineSeries
              key={index}
              data={eachLineData}
              style={getLineStyle(data[index].name, config)}
              onNearestX={(value, { index }) => {
                let point = multiLineData.map(d => d[index]);
                setCrosshairValues(point);
              }}
            />
          );
        })}
      </XYPlot>
    </div>
  );
}
