import React, { useMemo } from 'react';
import { IconStatusDefault, SkeletonLoader, SKELETON_SHAPES } from 'cdk-radial';

const numberFormat = new Intl.NumberFormat(undefined, {
  useGrouping: true
});

function TrafficCountNumber({ value, isLoading, placeholderWidth }) {
  return isLoading ? (
    <div className="traffic-count-digit-pending">
      <SkeletonLoader
        variant={SKELETON_SHAPES.RECTANGLE}
        height="52px"
        width={placeholderWidth}
      />
    </div>
  ) : (
    <div className="traffic-count-digit">{value}</div>
  );
}

export const AppRestApiMetricsCount = ({ traffic, clientError, isLoading }) => {
  const [
    trafficCount,
    clientErrorCount,
    serverErrorCount,
    clientErrorRate,
    serverErrorRate
  ] = useMemo(() => {
    if (traffic && Object.keys(traffic).length > 0) {
      let trafficCount = 0;
      let clientErrorCount = 0;
      let serverErrorCount = 0;
      let clientErrorRate = 0;
      let serverErrorRate = 0;

      if (Array.isArray(traffic) && traffic.length > 0) {
        trafficCount = traffic.reduce(
          (sum, e) => sum + Number(e.transactionCount),
          0
        );
      }

      if (Array.isArray(clientError) && clientError.length > 0) {
        clientErrorCount = clientError.reduce(
          (sum, e) => sum + Number(e.clientErrorCount),
          0
        );
        serverErrorCount = clientError.reduce(
          (sum, e) => sum + Number(e.serverErrorCount),
          0
        );
        clientErrorRate = Math.floor((clientErrorCount / trafficCount) * 100);
        serverErrorRate = Math.floor((serverErrorCount / trafficCount) * 100);
      }
      return [
        trafficCount,
        clientErrorCount,
        serverErrorCount,
        clientErrorRate,
        serverErrorRate
      ];
    }
    return [0, 0, 0, 0, 0];
  }, [traffic, clientError]);

  return (
    <>
      <div className="traffic-count-data">
        <div>
          <div className="traffic-count-header">
            <IconStatusDefault
              style={{
                fill: '#09AD00',
                width: '16px',
                height: '16px',
                marginRight: '5px'
              }}
            />
            Traffic
          </div>
          <div className="traffic-count-sub-header3">
            <div>Total</div>
            <div>Requests:</div>
          </div>
          <TrafficCountNumber
            value={numberFormat.format(trafficCount)}
            isLoading={isLoading}
            placeholderWidth="120px"
          />
        </div>
        <div className="traffic-count-block-border"></div>
        <div>
          <div className="traffic-count-header">
            <IconStatusDefault
              style={{
                fill: '#FCCD8C',
                width: '16px',
                height: '16px',
                marginRight: '5px'
              }}
            />
            4xx Errors and Transactions
          </div>
          <div className="traffic-error-count-data">
            <div>
              <div className="traffic-count-sub-header3">
                <div>Total</div>
                <div>4xx Errors:</div>
              </div>
              <TrafficCountNumber
                value={numberFormat.format(clientErrorCount)}
                isLoading={isLoading}
                placeholderWidth="100px"
              />
            </div>
            <div>
              <div className="traffic-count-sub-header2">4xx Transactions:</div>
              <TrafficCountNumber
                value={`${clientErrorRate}%`}
                isLoading={isLoading}
                placeholderWidth="100px"
              />
            </div>
          </div>
        </div>
        <div className="traffic-count-block-border"></div>
        <div>
          <div className="traffic-count-header">
            <IconStatusDefault
              style={{
                fill: '#EB0000',
                width: '16px',
                height: '16px',
                marginRight: '5px'
              }}
            />
            5xx Errors and Transactions
          </div>
          <div className="traffic-error-count-data">
            <div>
              <div className="traffic-count-sub-header3">
                <div>Total</div>
                <div>5xx Errors:</div>
              </div>
              <TrafficCountNumber
                value={numberFormat.format(serverErrorCount)}
                isLoading={isLoading}
                placeholderWidth="100px"
              />
            </div>
            <div>
              <div className="traffic-count-sub-header2">5xx Transactions:</div>
              <TrafficCountNumber
                value={`${serverErrorRate}%`}
                isLoading={isLoading}
                placeholderWidth="100px"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="traffic-count-section-border"></div>
    </>
  );
};
