import React, { useState, useEffect } from 'react';
import { Bar } from '@nivo/bar';
import { singlePointNumericFormatter } from './utils';

export const BarChart = ({ metricRecord }) => {
  const keys = ['success', 'failed'];
  const [barWidth, setBarWidth] = useState(400);
  const [metricData, setMetricData] = useState([]);

  useEffect(() => {
    if (
      !!metricRecord &&
      Array.isArray(metricRecord) &&
      metricRecord.length > 0
    ) {
      const mData = [];
      const apiIds = [...new Set(metricRecord.map(m => m.apiId))];
      apiIds.forEach(apiId => {
        mData.push({
          API: metricRecord.find(f => f.apiId === apiId)?.apiName || '',
          success:
            parseInt(
              metricRecord.find(
                f => f.apiId === apiId && f.status === 'success'
              )?.count
            ) || 0,
          failed:
            parseInt(
              metricRecord.find(
                f => f.apiId === apiId && f.status === 'failure'
              )?.count
            ) || 0
        });
      });
      setMetricData(mData);
      setBarWidth(300 + mData.length * 100);
    }
  }, [metricRecord]);

  const commonProps = {
    width: barWidth,
    height: 275,
    margin: { top: 60, right: 110, bottom: 60, left: 80 },
    indexBy: 'API',
    keys,
    padding: 0.2
  };

  const getTooltip = data => {
    const sCount = data?.success || 0;
    const fCount = data?.failed || 0;
    const successPct = ((sCount * 100) / (sCount + fCount)).toFixed(2);
    const failPct = ((fCount * 100) / (sCount + fCount)).toFixed(2);

    return (
      <>
        <div className="bar-chart-tooltip-wrapper">
          API Name: <strong>{data?.API}</strong>
          <div className="bar-chart-tooltip-count-item-wrapper">
            <div className="bar-chart-tooltip-count-color-wrapper">
              <div
                style={{
                  height: '15px',
                  width: '15px',
                  borderRadius: '2px',
                  background: '#0096D1',
                  marginRight: '5px'
                }}
              />
            </div>
            <div>
              <strong>Delivered:</strong> {sCount.toLocaleString()} (
              {successPct}%)
            </div>
          </div>
          <div className="bar-chart-tooltip-count-item-wrapper">
            <div className="bar-chart-tooltip-count-color-wrapper">
              <div
                style={{
                  height: '15px',
                  width: '15px',
                  borderRadius: '2px',
                  background: '#EE5396',
                  marginRight: '5px'
                }}
              />
            </div>
            <div>
              <strong>Failed:</strong> {fCount.toLocaleString()} ({failPct}%)
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="app-async-api-bar-wrapper">
        <div className="app-async-api-bar-chart">
          <Bar
            {...commonProps}
            data={metricData}
            layout="vertical"
            enableGridY={true}
            enableGridX={false}
            legendLabel={legnd => `${legnd.id}`}
            colors={['#0096D1', '#EE5396']}
            axisLeft={{
              format: value => singlePointNumericFormatter(value)
            }}
            valueFormat={val => singlePointNumericFormatter(val)}
            tooltip={({ data }) => getTooltip(data)}
          />
        </div>
        <div className="app-async-api-bar-legend-wrapper">
          <div className="bar-legend-box-wrapper">
            <div className="bar-legend-success-box"></div>
            <div className="bar-legend-unit-text">Success</div>
          </div>
          <div className="bar-legend-box-wrapper">
            <div className="bar-legend-failure-box"></div>
            <div className="bar-legend-unit-text">Failed</div>
          </div>
        </div>
      </div>
    </>
  );
};
