import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { withEntityContext } from '@cdk-prod/fortellis-entity-context';
import { SessionRefreshDialog } from '@fortellis/session-refresh-dialog';
const DEFAULT_TIMEOUT_IN_MINUTES = 10;

function AuthSessionRefreshDialog({ auth }) {
  return auth.isAuthenticated ? (
    <SessionRefreshDialog
      onSignOut={auth.logout}
      sessionExpiryTime={auth.expiresAt}
      Timeout={DEFAULT_TIMEOUT_IN_MINUTES}
      continueWorking={auth.continueWorking}
    />
  ) : null;
}

AuthSessionRefreshDialog.propTypes = {
  auth: PropTypes.object.isRequired
};

export default withRouter(
  withAuth(withEntityContext(AuthSessionRefreshDialog))
);
