import React, { useState, useEffect } from 'react';
import { LineSeriesChartCard } from '../../../metrics/LineSeriesChartCard';
import { BarChartCard } from '../../../metrics/BarChartCard';
import { AppAsyncApiMetricCount } from '../../../metrics/AppAsyncApiMetricCount';
import { Page, PageContent } from '@fortellis/page';
import { Grid, Column, Row } from 'cdk-radial';
import { IconChevronLeft } from '@cdk-rds/icons';
import { TextButton } from '@cdk-rds/button';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { AppMetricsInput } from '../../../metrics/AppMetricsInput';
import { AppRestApiMetricsCount } from './AppRestApiMetricsCount';
import axios from 'axios';
import {
  useAppMetricsFetch,
  useAppFetch,
  useAsyncApiEventsForAppFetch
} from '../../../metrics/fetchMetricsContext';
import {
  getAdaptiveTransactionCountMaxThreshold,
  singlePointNumericFormatter,
  getAdaptiveLatencyThreshold,
  latencyNumericFormatter,
  TIME_SPANS,
  LATENCIES,
  API_ENVS_FOR_APPS,
  ALL_APIS_OPTION
} from '../../../metrics/utils';
import AppMetricsHeader from './AppMetricsHeader';
import config from '../../../../config/config';
import {
  ejectInterceptor,
  getInterceptor
} from '../../../../utils/axios-utils';

import './app-metrics.scss';

const ASYNC_API_BASE_URL = config.api.asyncApiServiceBaseUrl;
const APIGATEWAY_DIRECTORY_BASE_URL = config.api.apiGatewayDirectoryBaseUrl;

function errorRateCrossHairFormatter(input) {
  return `${input}%`;
}

function getAdaptiveTrafficChartConfig(maxValue = 500) {
  return {
    chart: {
      height: 225,
      width: 525,
      yAxiscounterName: 'Total Requests',
      yAxisCounterRange: [0, getAdaptiveTransactionCountMaxThreshold(maxValue)],
      yAxisTickFormat: singlePointNumericFormatter,
      yAxisTicksTotal: 5,
      xAxisTicksTotal: 7,
      lineConfig: {
        count: {
          stroke: '#09AD00',
          label: 'Request Count',
          hoverText: 'Requests'
        }
      },
      labelProperty: 'apiName',
      showLegend: false,
      showCrossHairs: true,
      crossHairValueFormatter: singlePointNumericFormatter
    }
  };
}

function getAdaptiveLatencyChartConfig(maxValue = 10000) {
  return {
    chart: {
      height: 225,
      width: 525,
      yAxiscounterName: 'Milliseconds',
      yAxisCounterRange: [0, getAdaptiveLatencyThreshold(maxValue)],
      yAxisTickFormat: latencyNumericFormatter,
      xAxisTicksTotal: 7,
      yAxisTicksTotal: 5,
      lineConfig: {
        p99Latency: {
          stroke: '#6B2574',
          label: 'P99',
          hoverText: 'P99 Latency'
        },
        p95Latency: {
          stroke: '#EE5396',
          label: 'P95',
          hoverText: 'P95 Latency'
        },
        p90Latency: {
          stroke: '#0096D1',
          label: 'P90',
          hoverText: 'P90 Latency'
        }
      },
      labelProperty: 'apiName',
      showLegend: false,
      showCrossHairs: true,
      crossHairValueFormatter: latencyNumericFormatter
    }
  };
}

const errorRatesChartConfig = {
  chart: {
    height: 225,
    width: 525,
    yAxiscounterName: 'Percentage of all Transactions',
    yAxisCounterRange: [0, 100],
    xAxisTicksTotal: 7,
    yAxisTicksTotal: 5,
    lineConfig: {
      clientErrorRate: { stroke: '#FCCD8C', label: '4xx', hoverText: '4xx' },
      serverErrorRate: { stroke: '#EB0000', label: '5xx', hoverText: '5xx' }
    },
    labelProperty: 'apiName',
    showLegend: false,
    showCrossHairs: true,
    crossHairValueFormatter: errorRateCrossHairFormatter
  }
};

function getMaxValueOfEntries(metricEntries) {
  let maxValue = 0;
  for (const entry of metricEntries) {
    maxValue = entry.maxValue > maxValue ? entry.maxValue : maxValue;
  }
  return maxValue;
}

function AppMetrics({ auth, match }) {
  const [filters, setFilters] = useState({
    timeRange: TIME_SPANS[0].value,
    latencies: [LATENCIES[0].value],
    apiId: ALL_APIS_OPTION.value,
    environment: API_ENVS_FOR_APPS[0].value,
    kind: undefined
  });
  const [refresh, triggerRefresh] = useState(false);
  const [filteredLatency, setFilteredLatency] = useState([]);
  const [filteredTraffic, setFilteredTraffic] = useState([]);
  const [clientError, setClientError] = useState([]);
  const [serverError, setServerError] = useState([]);
  const [apiOptions, setApiOptions] = useState([]);
  const [restCardsShow, setRestCardsShow] = useState(false);
  const [asyncCardsShow, setAsyncCardsShow] = useState(false);
  const [asyncMetric, setAsyncMetric] = useState([]);

  const [
    appInfoIsLoading,
    isAppInfoRequestFulfilled,
    appInfoError,
    appInfoData,
    fetchApp
  ] = useAppFetch({});

  const [
    appDetailMetricsIsLoading,
    isAppDetailMetricsRequestFulfilled,
    appDetailMetricsError,
    appDetailMetricsResponse,
    fetchAppDetailMetrics
  ] = useAppMetricsFetch();

  const [
    asyncEventIsLoading,
    isAsyncEventRequestFulfilled,
    asyncEventError,
    asyncEventResponse,
    fetchAsyncEvents
  ] = useAsyncApiEventsForAppFetch();

  const { transactions = [], latency = [], errorRate = [] } =
    appDetailMetricsResponse || {};

  useEffect(
    function() {
      if (auth.isAuthenticated) {
        fetchApp(auth.accessToken || '', match.params.id);
      }
    },
    [auth]
  );

  useEffect(() => {
    const interceptor = getInterceptor(auth.accessToken);
    return () => {
      ejectInterceptor(interceptor);
    };
  }, [auth?.accessToken]);

  useEffect(() => {
    setFilteredTraffic(
      transactions.filter(e => e.apiInstanceEnvironment === filters.environment)
    );
    setFilteredLatency(
      latency.filter(
        e =>
          filters.latencies.includes(e.name) &&
          e.apiInstanceEnvironment === filters.environment
      )
    );
    setClientError(
      errorRate.filter(
        e =>
          e.name === 'clientErrorRate' &&
          e.apiInstanceEnvironment === filters.environment
      )
    );
    setServerError(
      errorRate.filter(
        e =>
          e.name === 'serverErrorRate' &&
          e.apiInstanceEnvironment === filters.environment
      )
    );
  }, [filters, appDetailMetricsResponse, filters.environment]);

  useEffect(() => {
    !!asyncEventResponse && setAsyncMetric(asyncEventResponse);
  }, [asyncEventResponse]);

  useEffect(
    function() {
      if (auth.isAuthenticated) {
        fetchAppDetailMetrics(match.params.id, auth.accessToken || '', filters);
      }
    },
    [refresh, filters.timeRange, filters.apiId, auth.isAuthenticated]
  );

  useEffect(() => {
    if (auth.isAuthenticated && asyncCardsShow) {
      fetchAsyncEvents(match.params.id, auth.accessToken || '', filters);
    }
  }, [
    refresh,
    filters.timeRange,
    filters.apiId,
    auth.isAuthenticated,
    asyncCardsShow
  ]);

  // Set list of api options
  useEffect(() => {
    let apiOptions = [];
    if (!!appInfoData) {
      getAllApis(appInfoData.asyncApiIntegrations, appInfoData.apiProducts);
    }

    // Only need to set api list when fetching all apis on the app,
    // otherwise the individual api will override the list
  }, [appInfoData]);

  useEffect(() => {
    if (filters.kind === undefined) {
      setRestCardsShow(true);
      setAsyncCardsShow(true);
    } else if (filters.kind === 'rest') {
      setRestCardsShow(true);
      setAsyncCardsShow(false);
    } else if (filters.kind === 'async') {
      setRestCardsShow(false);
      setAsyncCardsShow(true);
    }
    if (!!apiOptions && Array.isArray(apiOptions) && apiOptions.length > 0) {
      !apiOptions.find(f => f.kind === 'rest') && setRestCardsShow(false);
      !apiOptions.find(f => f.kind === 'async') && setAsyncCardsShow(false);
    } else {
      setRestCardsShow(false);
      setAsyncCardsShow(false);
    }
  }, [filters, apiOptions]);

  async function getAllApis(asyncApiIntegrations, apiProducts) {
    let promiseResolvedApi = [];
    let promiseRejectedApi = [];
    let instanceId = '';
    if (!!apiProducts && apiProducts.length) {
      await Promise.allSettled(
        apiProducts.map(apiId => {
          const id = apiId.replace('api-v2-', '');
          return axios.get(
            `${APIGATEWAY_DIRECTORY_BASE_URL}/directory/apis/${id}`
          );
        })
      ).then(results => {
        results.forEach(result => {
          result.status === 'fulfilled'
            ? promiseResolvedApi.push(result.value.data)
            : promiseRejectedApi.push(result.reason);
        });
      });
      prepareApiDropdownOption(promiseResolvedApi);
    }
    if (!!asyncApiIntegrations && asyncApiIntegrations.length > 0) {
      await Promise.allSettled(
        asyncApiIntegrations.map(api => {
          instanceId = api?.asyncApi?.id;
          return axios.get(
            `${ASYNC_API_BASE_URL}/v2/directory/async-apis/${instanceId}`
          );
        })
      ).then(results => {
        results.forEach(result => {
          result.status === 'fulfilled'
            ? promiseResolvedApi.push(result.value.data)
            : promiseRejectedApi.push(result.reason);
        });
      });
      prepareApiDropdownOption(promiseResolvedApi);
    }
  }
  const prepareApiDropdownOption = apis => {
    let apisOptions = [];
    apis.forEach(api => {
      const obj = {};
      obj['label'] = api.name;
      obj['value'] = api.id;
      obj['kind'] = api.kind === 'api' ? 'rest' : 'async';
      apisOptions.push(obj);
    });
    setApiOptions(apisOptions);
  };

  function onFilterChange(change) {
    setFilters({ ...filters, ...change });
  }
  return (
    <>
      <div className="fdn-back-button">
        <div className="fdn-back-button-content">
          <TextButton
            href={`/developer-account/solutions/view/${match.params.id}`}
            icon={<IconChevronLeft />}
            text="Back"
          />
        </div>
      </div>
      <Page className="frc-page__app-metrics">
        <PageContent>
          <AppMetricsHeader
            appName={appInfoData?.displayName}
            apiOptions={apiOptions}
            isAppLoading={appInfoIsLoading}
            isMetricsLoading={appDetailMetricsIsLoading}
            apiSelected={filters.apiId}
            onApiChange={onFilterChange}
            environmentSelected={filters.environment}
            onEnvironmentChange={onFilterChange}
          />
          <AppMetricsInput
            refresh={refresh}
            triggerRefresh={triggerRefresh}
            onFilterChange={onFilterChange}
            filters={filters}
            isLoading={appDetailMetricsIsLoading || appInfoIsLoading}
            restCardsShow={restCardsShow}
          />
          {restCardsShow && (
            <AppRestApiMetricsCount
              traffic={filteredTraffic}
              isLoading={appDetailMetricsIsLoading || appInfoIsLoading}
              clientError={clientError}
            />
          )}
          <Grid>
            {restCardsShow && (
              <>
                <Row style={{ gap: '16px' }}>
                  <Column>
                    <LineSeriesChartCard
                      title="Traffic"
                      yAxiscounterName="Request / minute"
                      config={getAdaptiveTrafficChartConfig(
                        getMaxValueOfEntries(filteredTraffic)
                      )}
                      data={filteredTraffic}
                      isLoading={appDetailMetricsIsLoading || appInfoIsLoading}
                      isRequestFulfilled={
                        isAppDetailMetricsRequestFulfilled &&
                        isAppInfoRequestFulfilled
                      }
                      error={appDetailMetricsError}
                    />
                  </Column>
                  <Column>
                    <LineSeriesChartCard
                      title="Latency"
                      yAxiscounterName="Request / minute"
                      config={getAdaptiveLatencyChartConfig(
                        getMaxValueOfEntries(filteredLatency)
                      )}
                      data={filteredLatency}
                      isLoading={appDetailMetricsIsLoading || appInfoIsLoading}
                      isRequestFulfilled={
                        isAppDetailMetricsRequestFulfilled &&
                        isAppInfoRequestFulfilled
                      }
                      error={appDetailMetricsError}
                    />
                  </Column>
                </Row>
                <Row style={{ gap: '16px' }}>
                  <Column>
                    <LineSeriesChartCard
                      title="4xx Error Rate"
                      yAxiscounterName="Request / minute"
                      config={errorRatesChartConfig}
                      data={clientError}
                      isLoading={appDetailMetricsIsLoading || appInfoIsLoading}
                      isRequestFulfilled={
                        isAppDetailMetricsRequestFulfilled &&
                        isAppInfoRequestFulfilled
                      }
                      error={appDetailMetricsError}
                    />
                  </Column>
                  <Column>
                    <LineSeriesChartCard
                      title="5xx Error Rate"
                      yAxiscounterName="Request / minute"
                      config={errorRatesChartConfig}
                      data={serverError}
                      isLoading={appDetailMetricsIsLoading || appInfoIsLoading}
                      isRequestFulfilled={
                        isAppDetailMetricsRequestFulfilled &&
                        isAppInfoRequestFulfilled
                      }
                      error={appDetailMetricsError}
                    />
                  </Column>
                </Row>
              </>
            )}
            {asyncCardsShow && (
              <>
                <Row>
                  <Column>
                    <AppAsyncApiMetricCount
                      isLoading={asyncEventIsLoading}
                      metricRecord={asyncMetric}
                    />
                  </Column>
                </Row>
                <Row style={{ gap: '16px' }}>
                  <Column>
                    <BarChartCard
                      metricRecord={asyncMetric}
                      isLoading={asyncEventIsLoading}
                      isRequestFulfilled={isAsyncEventRequestFulfilled}
                      error={asyncEventError}
                    />
                  </Column>
                </Row>
              </>
            )}
          </Grid>
        </PageContent>
      </Page>
    </>
  );
}

export default withAuth(AppMetrics);
