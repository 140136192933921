import React, { Component, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from 'react-router-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import AuthFooter from './components/footer/AuthFooter';
import AuthHeader from './components/header/AuthHeader';
import AuthSessionRefreshDialog from './components/sessionRefresh/AuthSessionRefreshDialog';
import { withAuthLoader } from './components/authLoader';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/home/Home';
import EntityProvider from './components/entity-context/EntityProvider';
import NotFound from './components/notfound';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import { LocaleProvider, LocaleConsumer } from '@fortellis/locale';
import DelayedLoadingSection from './components/loading/DelayedLoadingSection';
import { CaptchaProvider } from './components/captcha/CaptchaProvider';
import { DialogQueue } from '@rmwc/dialog';
import { SnackbarQueue } from '@rmwc/snackbar';
import { dialogQueue, snackbarQueue } from './components/Queues';
import ApiMetrics from './components/developer-account/api/api-metrics/ApiMetrics';
import AppMetrics from './components/developer-account/solution/app-metrics/AppMetrics';
import AsyncApiEventsMetrics from './components/developer-account/api/api-metrics/AsyncApiEventsMetrics';
import Terms from './components/website-terms/Terms';

import dnpLocaleData from './locales/data.json';
import footerLocaleData from '@fortellis/footer/locales/data.js';
import headerLocaleData from '@cdk-prod/fortellis-header/locales/data.json';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';

import config from './config/config';
import '@cdk-rds/fonts/dist/fonts.css';
import './app.scss';
import '@rmwc/list/collapsible-list.css';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import '@rmwc/tooltip/tooltip.css';
import '@rmwc/circular-progress/circular-progress.css';
import '@material/menu/dist/mdc.menu.css';
import { ThemeProvider } from 'styled-components';
import { fortellisTheme } from '@cdk-rds/core';

import {
  getAuthContext,
  HideSignOut,
  withAuth
} from '@cdk-prod/fortellis-auth-context';
import { AhaWidget } from '@cdk-prod/fortellis-aha-widget';
import UtilizationPage from './components/developer-account/solution/UtilizationPage/UtilizationPage';

const ACCOUNTS_SERVER_BASE_URL = config.api.accountsServerUrl;
const DOCUMENTS_SERVICE_BASE_URL = config.api.apiEsignUrl;

// Lazy load components for code splitting
// Preload developer-account
const DeveloperAccountContainerPromise = import(
  './components/developer-account/developer-account-page/DeveloperAccountContainer'
);
const DeveloperAccountContainer = lazy(() => DeveloperAccountContainerPromise);

const EntityRegistration = lazy(() =>
  import('./components/entity-management/registration/EntityRegistration')
);
const EntityAccounts = lazy(() =>
  import('./components/entity-management/accounts/EntityAccounts')
);
const MembersList = lazy(() =>
  import('./components/entity-management/members-management/MembersList')
);

const ApiImplementation = lazy(() =>
  import('./components/developer-account/api-implementation')
);
const ApiManage = lazy(() =>
  import('./components/developer-account/api/api-manage')
);
const SolutionRegistrationContainer = lazy(() =>
  import(
    './components/developer-account/solution/SolutionRegistrationContainer'
  )
);
const Api = lazy(() =>
  import('./components/developer-account/api/api-details')
);

const SolutionOneToOneView = lazy(() =>
  import('./components/developer-account/solution/SolutionOneToOneView')
);

const ApiErrorsList = lazy(() =>
  import(
    './components/developer-account/api/api-details/api-error/ApiErrorsList'
  )
);

const AsyncApiErrorsList = lazy(() =>
  import(
    './components/developer-account/api/api-details/api-error/AsyncApiErrorsList'
  )
);

const ApiErrorList = lazy(() =>
  import(
    './components/developer-account/api/api-details/api-error/ApiErrorList'
  )
);

const AsyncApiErrorList = lazy(() =>
  import(
    './components/developer-account/api/api-details/api-error/AsyncApiErrorList'
  )
);

const AdvancedPricingPlanForm = lazy(() =>
  import(
    './components/developer-account/api-implementation/pricing/AdvancedForm'
  )
);

const AppIntegrations = lazy(() =>
  import(
    './components/developer-account/api/api-manage/AppIntegrations/AppIntegrations'
  )
);

const PrivateRouteWithLoad = withAuthLoader(SecureRoute);

const localeData = {
  en: { ...dnpLocaleData.en, ...headerLocaleData.en, ...footerLocaleData.en },
  fr: { ...dnpLocaleData.fr, ...headerLocaleData.fr, ...footerLocaleData.fr }
};

const FOOTER_HEIGHT = '252px';
const FOOTER_HEIGHT_MOBILE = '388px';

addLocaleData([...en, ...fr]);

const env = {
  issuer: process.env.REACT_APP_AUTH_ISSUER,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: false
};

const applicationId = process.env.REACT_APP_PLATFORM_APPLICATIONID_FORTELLIS;
const isAhaEnabled =
  process.env.REACT_APP_FEATURE_AHA_WIDGET_ENABLED === 'true';

const AhaWidgetWithAuth = withAuth(AhaWidget);

class Root extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: false,
      footerHeight: FOOTER_HEIGHT
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.updateMobile();
    window.addEventListener('resize', this.updateMobile);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updateMobile);
  }

  updateMobile = () => {
    if (!this.mounted) return;

    if (window.innerWidth <= 1039) {
      this.setState({ mobile: true, footerHeight: FOOTER_HEIGHT_MOBILE });
    } else {
      this.setState({ mobile: false, footerHeight: FOOTER_HEIGHT });
    }
  };

  render() {
    if (config.env !== 'prod') {
      console.warn(`Application is currently running in '${config.env}' mode`);
    }

    if (window.location.pathname === '/signingPreviewComplete') {
      return (
        <div className={'App__signingPreviewComplete'}>
          <strong>Signing/Preview Complete</strong>
        </div>
      );
    }

    return (
      <>
        <ScrollToTop>
          <ThemeProvider theme={fortellisTheme}>
            <Security
              oktaAuth={this.props.oktaAuth}
              restoreOriginalUri={this.props.restoreOriginalUri}
            >
              <LocaleProvider>
                <LocaleConsumer>
                  {localeContext => (
                    <IntlProvider
                      locale={localeContext.locale}
                      messages={localeData[localeContext.locale]}
                    >
                      <EntityProvider>
                        <HideSignOut />
                        <CaptchaProvider>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              minHeight: '100vh'
                            }}
                          >
                            {isAhaEnabled && (
                              <AhaWidgetWithAuth
                                applicationId={applicationId}
                              />
                            )}
                            <AuthSessionRefreshDialog />
                            <AuthHeader mobile={this.state.mobile} />
                            <div style={{ flexGrow: 1 }}>
                              <ErrorBoundary>
                                <Suspense
                                  fallback={
                                    <DelayedLoadingSection delay={500} />
                                  }
                                >
                                  <Terms
                                    accountsServerBaseUrl={
                                      ACCOUNTS_SERVER_BASE_URL
                                    }
                                    documentsServiceBaseUrl={
                                      DOCUMENTS_SERVICE_BASE_URL
                                    }
                                  />

                                  <Switch>
                                    <Route
                                      exact
                                      path="/"
                                      component={() => (
                                        <Home mobile={this.state.mobile} />
                                      )}
                                    />
                                    <Route
                                      // Redirect old api reference links to the new api reference site
                                      path="/api-reference"
                                      component={() => {
                                        window.location.replace(
                                          config.apidocs.url
                                        );
                                        return null;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path={'/login/callback'}
                                      component={LoginCallback}
                                    />
                                    <Route
                                      // Redirect old contact-us links to fortellis.io's
                                      exact
                                      path="/contact-us"
                                      component={() => {
                                        window.location.replace(
                                          config.contact.url
                                        );
                                        return null;
                                      }}
                                    />
                                    <Route
                                      // show support assests publicly
                                      path="/support"
                                      onEnter={() => window.location.reload()}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/solutions/register/:name?"
                                      component={SolutionRegistrationContainer}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/solutions/edit/:id?"
                                      component={SolutionRegistrationContainer}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/solutions/view/:id?"
                                      component={SolutionOneToOneView}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/specs/register/:id?"
                                      component={() => (
                                        <Redirect to="/developer-account" />
                                      )}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/apis/register/:id?"
                                      component={ApiManage}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/apis/:id/metrics"
                                      component={ApiMetrics}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/async-apis/:id/metrics"
                                      component={AsyncApiEventsMetrics}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/apis/:id/version/:instanceId"
                                      component={ApiManage}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/async-apis/:id/version/:instanceId"
                                      component={ApiManage}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/apis/:id/error"
                                      component={ApiErrorsList}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/async-apis/:id/error"
                                      component={AsyncApiErrorsList}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/async-api/app/:id/error"
                                      component={AsyncApiErrorList}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/rest-api/app/:id/error"
                                      component={ApiErrorList}
                                    />
                                    <PrivateRouteWithLoad
                                      exact
                                      path="/developer-account/apis/app-integrations/:id"
                                      component={AppIntegrations}
                                    />
                                    <PrivateRouteWithLoad
                                      exact
                                      path="/developer-account/async-apis/app-integrations/:id"
                                      component={AppIntegrations}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/solutions/:id/metrics"
                                      component={AppMetrics}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/solutions/:id/utilization"
                                      component={UtilizationPage}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/apis/register/:id/version"
                                      component={ApiManage}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/implementations/usage"
                                      component={DeveloperAccountContainer}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/implementations/:id"
                                      component={ApiImplementation}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/apis"
                                      component={Api}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/async-apis"
                                      component={Api}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account"
                                      component={DeveloperAccountContainer}
                                    />
                                    <PrivateRouteWithLoad
                                      exact
                                      path="/org-management/registration"
                                      component={EntityRegistration}
                                    />
                                    <PrivateRouteWithLoad
                                      exact
                                      path="/org-management"
                                      component={EntityAccounts}
                                    />
                                    <PrivateRouteWithLoad
                                      exact
                                      path="/org-management/members"
                                      component={MembersList}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/apis/:id/metrics"
                                      component={ApiMetrics}
                                    />
                                    <PrivateRouteWithLoad
                                      path="/developer-account/apis/:id/pricing/advanced"
                                      component={AdvancedPricingPlanForm}
                                    />

                                    <Route component={NotFound} />
                                  </Switch>
                                </Suspense>
                              </ErrorBoundary>
                            </div>
                            <AuthFooter mobile={this.state.mobile} />
                          </div>
                          {/* <EntityPopup /> [Dec 11, 2018] - JC - Temporarily comment out for authorizer deployment */}
                        </CaptchaProvider>
                      </EntityProvider>
                    </IntlProvider>
                  )}
                </LocaleConsumer>
              </LocaleProvider>
            </Security>
          </ThemeProvider>
        </ScrollToTop>
        <DialogQueue dialogs={dialogQueue.dialogs} />
        <SnackbarQueue messages={snackbarQueue.messages} />
      </>
    );
  }
}

const RootWithRouterAccess = withRouter(
  getAuthContext({ WrappedComponent: Root, env })
);

class App extends Component {
  render() {
    return (
      <Router>
        <RootWithRouterAccess />
      </Router>
    );
  }
}

export default App;
