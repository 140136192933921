import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import classNames from 'classnames';
import {
  TableHeader,
  TableContent,
  usePreventTableScrollingRef,
  TableContainer,
  CELL_RENDERERS,
  TextRenderer,
  IconRenderer,
  RatingRenderer,
  ROW_SELECTION,
  classNamePrefix
} from 'cdk-radial';

export const AsyncApiDeliveryTable = ({ apiDeliveries }) => {
  const componentMainClass = `${classNamePrefix}-table`;
  const agGridThemeClass = 'ag-theme-balham';
  const tableClass = classNames(componentMainClass, agGridThemeClass);
  const [gridApi, setGridApi] = useState(null);
  const [deliveries, setDeliveries] = useState([]);

  const columns = [
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'appId',
      headerName: 'App ID',
      minWidth: 100,
      width: 380
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'appName',
      headerName: 'App Name',
      minWidth: 100,
      width: 500
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'success',
      headerName: 'Successful Deliveries',
      minWidth: 100,
      width: 250
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'failure',
      headerName: 'Failed Deliveries',
      minWidth: 100,
      width: 250
    }
  ];

  const tableContentRef = usePreventTableScrollingRef();
  const frameworkComponentsExtended = {
    textRenderer: TextRenderer,
    iconRenderer: IconRenderer,
    ratingRenderer: RatingRenderer
  };

  const handleGridReady = gridParams => {
    setGridApi(gridParams.api);
    gridParams.api.sizeColumnsToFit();
  };

  const headerProps = {
    dataTestId: `datatest-async-api-delivery`,
    headerTitle: `Async API Delivery Details`,
    hideBorder: true,
    id: `async-api-delivery`,
    isDownloadable: false,
    isPrintable: false,
    isSearchable: false,
    searchInputName: 'table-search-input-name'
  };

  useEffect(() => {
    if (!!apiDeliveries) {
      let locDelivery = [...new Set(apiDeliveries.map(m => m.appId))].map(m => {
        return {
          appId: m,
          appName: apiDeliveries.find(f => f.appId === m)?.appName || '',
          success:
            apiDeliveries.find(f => f.appId === m && f.status === 'success')
              ?.count || 0,
          failure:
            apiDeliveries.find(f => f.appId === m && f.status === 'failure')
              ?.count || 0
        };
      });
      setDeliveries(locDelivery);
    }
  }, [apiDeliveries]);

  return (
    <TableContainer
      className={tableClass}
      style={{
        width: '100vw',
        height: '20vh'
      }}
      tableLayout={'normal'}
    >
      <TableHeader {...headerProps} />
      <TableContent data-testid="table-content" ref={tableContentRef}>
        <AgGridReact
          defaultColDef={{
            width: 300,
            filter: true,
            sortable: true
          }}
          frameworkComponents={frameworkComponentsExtended}
          columnDefs={columns}
          onGridReady={handleGridReady}
          rowData={deliveries}
          rowSelection={ROW_SELECTION.SINGLE}
          rowHeight={48}
        />
      </TableContent>
    </TableContainer>
  );
};
