import React, { useRef, useState, useEffect } from 'react';
import {
  Menu,
  MenuListItem,
  Input,
  IconArrowDropUp,
  IconArrowDropDown
} from 'cdk-radial';
import './api-error.scss';

export const ApiIntegratedAppParam = ({
  integratedAppList,
  environment,
  onAppIdSelection
}) => {
  const targetRefApp = useRef();
  const dimensionRefApp = useRef();
  const [appList, setAppList] = useState([]);
  const [isAppOpen, setIsAppOpen] = useState(false);
  const [selectedAppId, setSelectedAppId] = useState('allApp');

  const handleAppOpen = _ => setIsAppOpen(!isAppOpen);
  const handleAppSelect = option => {
    setSelectedAppId(option.appId);
    onAppIdSelection(option.appId);
  };

  useEffect(() => {
    if (!!integratedAppList && !!environment) {
      let locIntegratedAppList = [];
      [...new Set(integratedAppList.map(m => m.appId))].forEach(
        f =>
          (locIntegratedAppList = [
            ...locIntegratedAppList,
            integratedAppList.find(i => i.appId === f)
          ])
      );

      let localAppList = [
        { appId: 'allApp', appName: 'All Apps', env: environment }
      ];
      localAppList = [
        ...localAppList,
        ...locIntegratedAppList.filter(f => f.env === environment)
      ];
      setAppList(localAppList);
    }
  }, [integratedAppList, environment]);

  return (
    <>
      <div className="api-error-version">
        <div style={{ minWidth: '530px' }} data-testid="app-data-testid-ssd">
          <Input
            dataTestId="menu-input"
            icon={isAppOpen ? <IconArrowDropDown /> : <IconArrowDropUp />}
            id="app-single-select-dropdown"
            label="App"
            name="app-single-select-dropdown"
            onChange={() => {}}
            placeholder="Select App"
            labelRef={dimensionRefApp}
            ref={targetRefApp}
            value={
              appList.find(f => f.appId === selectedAppId)?.appName ||
              'All Apps'
            }
            style={{ caretColor: 'transparent' }}
          />
        </div>
        <Menu
          dataTestId="menu-container"
          onClose={handleAppOpen}
          onOpen={handleAppOpen}
          dimensionRef={dimensionRefApp}
          labelRef={targetRefApp}
          style={{ width: '530px' }}
          isAuto={false}
          className="api-error-menu"
        >
          {appList.map((option, ind) => (
            <MenuListItem
              dataTestId={`menu-item-${ind}`}
              key={ind}
              hideSelectedItemCheckmark={false}
              onClick={() => {
                handleAppSelect(option);
              }}
              isSelected={option.value === environment}
              isDisabled={option.disabled}
            >
              {option?.appName}
            </MenuListItem>
          ))}
        </Menu>
      </div>
    </>
  );
};
