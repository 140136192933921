import React from 'react';
import { Grid, Column, Row } from 'cdk-radial';
import {
  DropdownMenu,
  IconRefresh,
  PrimaryButton,
  FilterChips
} from 'cdk-radial';
import {
  TIME_SPANS,
  getimeSpanOption,
  LATENCIES,
  ALL_APIS_OPTION
} from './utils';

export function AppMetricsInput({
  refresh,
  triggerRefresh,
  filters,
  onFilterChange,
  isLoading,
  restCardsShow
}) {
  return (
    <Grid className="api-visualization">
      <Row style={{ paddingBottom: '20px' }}>
        <Column l={1.5} style={{ marginRight: '20px' }}>
          <DropdownMenu
            inputId="time-span"
            label="Time Span"
            value={getimeSpanOption(filters.timeRange)}
            onChange={e => {
              onFilterChange({ timeRange: e.value });
            }}
            options={TIME_SPANS}
            isDisabled={isLoading}
          />
        </Column>

        <Column>
          {restCardsShow && (
            <Row>
              <div
                style={{
                  marginTop: '6px',
                  marginRight: '8px',
                  fontWeight: 'bold'
                }}
              >
                Latency:
              </div>
              <FilterChips
                dataTestId="latency-filter-chips"
                options={
                  filters.apiId === ALL_APIS_OPTION.value
                    ? [LATENCIES[0]]
                    : LATENCIES
                }
                onChange={e => {
                  onFilterChange({ latencies: e });
                }}
                value={filters.latencies}
              />
            </Row>
          )}
        </Column>

        <Column m={1} s={12} xs={12}>
          <PrimaryButton
            style={{ marginTop: '4px' }}
            dataTestId="refresh-button"
            icon={<IconRefresh />}
            iconColor="primary"
            onClick={function() {
              triggerRefresh(!refresh);
            }}
            text="Refresh"
            size="large"
            isDisabled={isLoading}
          />
        </Column>
      </Row>
    </Grid>
  );
}
