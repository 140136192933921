import axios from 'axios';

export function response(res) {
  return res.data;
}

export function error(err) {
  if (err.response) {
    const { status, statusText, data } = err.response;
    const { code, message } = data;
    const meta = { error: { status, statusText, code, message } };
    console.log(meta);

    const error = new Error(message);
    error.status = status;
    error.statusText = statusText;
    error.code = code;
    error.data = data;
    return error;
  }

  const append = err.message ? ` ${err.message}` : '';
  if (err.request) {
    const message = `The request was made but no response was received.${append}`;
    return message;
  } else {
    const message = `Something happened in setting up the request that triggered an error.${append}`;
    return message;
  }
}

export function getInterceptor(accessToken) {
  const Authorization = accessToken ? `Bearer ${accessToken}` : undefined;
  return axios.interceptors.request.use(config => {
    return { ...config, headers: { ...config.headers, Authorization } };
  });
}

export function ejectInterceptor(interceptor) {
  axios.interceptors.request.eject(interceptor);
}
