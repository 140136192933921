import React from 'react';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { Flex, FlexItem } from '@fortellis/flex';
import { Button } from '@rmwc/button';
import { Card, CardPrimaryAction, CardMedia } from '@rmwc/card';

import config from '../../config/config';

import task2Fast from '../../assets/Task2Fast.jpg';
import website2Sales from '../../assets/Website2Sales.jpg';
import gameChanger from '../../assets/exchange-gamechanger.jpg';
import './home.scss';

const { signup, apidocs } = config;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.twitter = null;
    this.state = {
      task2FastHeight: '',
      site2SalesHeight: '',
      gameChangerHeight: ''
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(prevProps) {
    const { mobile } = this.props;
    const { mobile: prevMobile } = prevProps;

    if (mobile !== prevMobile && this.mounted) {
      if (mobile) {
        this.setState({
          task2FastHeight: 'inherit',
          site2SalesHeight: 'inherit',
          gameChangerHeight: 'inherit'
        });
      } else {
        this.setState({
          task2FastHeight: document.getElementById('task-to-fast-text-section')
            .clientHeight,
          site2SalesHeight: document.getElementById(
            'site-to-sales-text-section'
          ).clientHeight,
          gameChangerHeight: document.getElementById(
            'game-changer-text-section'
          ).clientHeight
        });
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  get classes() {
    const { mobile } = this.props;
    return classnames('fdn-cover', {
      'fdn-cover--mobile': mobile
    });
  }

  render() {
    const {
      auth: { isAuthenticated },
      mobile
    } = this.props;
    return (
      <Flex direction="column">
        <FlexItem>
          <Flex className={this.classes} justify="center">
            <FlexItem className="fdn-cover__headline">
              <div className="fdn-cover__title">Developer Network</div>
              <div className="fdn-cover__subtitle">
                <FormattedMessage
                  id="home.cover.subtitle"
                  defaultMessage="The future of automotive commerce is in your hands"
                />
              </div>
              <div className="fdn-cover__cta-buttons">
                <Button
                  className="fdn-cover__button"
                  outlined
                  href={apidocs.url}
                  tag="a"
                >
                  <FormattedMessage
                    id="home.cover.btn.explore"
                    defaultMessage="Explore All APIs"
                  />
                </Button>
                {isAuthenticated ? (
                  <Link to="/developer-account">
                    <Button
                      className="fdn-cover__button"
                      outlined
                      data-cy="dev_acc_strt_build"
                    >
                      <FormattedMessage
                        id="home.cover.btn.startBuilding"
                        defaultMessage="Start Building"
                      />
                    </Button>
                  </Link>
                ) : (
                  <Button
                    className="fdn-cover__button--highlighted"
                    href={signup.url}
                    unelevated
                    tag="a"
                  >
                    <FormattedMessage
                      id="home.cover.btn.getStarted"
                      defaultMessage="Get Started"
                    />
                  </Button>
                )}
              </div>
            </FlexItem>
          </Flex>
        </FlexItem>
        <FlexItem>
          <Flex justify="center">
            <FlexItem className="fdn-home-section">
              <Flex
                direction={mobile ? 'column' : 'row'}
                justify="between"
                style={{ padding: '2em 0em' }}
              >
                <PersonaCard
                  title="Develop Innovative Apps"
                  description="Bring your ideas to life by harnassing the power of the Fortellis platform. Bring innovation to the automotive industry by enhancing customer experience and maximizing revenue opportunities."
                  imageUrl={task2Fast}
                  href="/developer-account"
                />
                <PersonaCard
                  title="Write Industry Standard API Specs"
                  description="Help define the API Specs that will transform the automotive ecommerce industry."
                  imageUrl={website2Sales}
                  href="/developer-account"
                />
                <PersonaCard
                  title="Implement APIs & Provide Data"
                  description="Empower creators while opening your business to an expansive market of dealers and other players in the automotive industry."
                  imageUrl={gameChanger}
                  href="/developer-account"
                />
              </Flex>
            </FlexItem>
          </Flex>
        </FlexItem>
        <FlexItem className="fdn-home-section-bg">
          <Flex justify="center">
            <FlexItem className="fdn-home-section">
              <Flex direction="column" className="fdn-home-section__content">
                <h2>The Future of Automotive Commerce is in Your Hands</h2>
                <h1>Why Fortellis Developer Network?</h1>
                <p>
                  Take advantage of the automotive data platform from the
                  future:
                </p>
                <ul>
                  <li>Integrate custom apps with business workflows</li>
                  <li>Collaborate with other great developers</li>
                  <li>Gain access to a large network of consumers</li>
                  <li>Participate in knowledge-based community</li>
                </ul>
              </Flex>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
    );
  }
}

Home.defaultProps = {
  mobile: false,
  auth: {
    isAuthenticated: false
  }
};

function PersonaCardView({ title, description, href, imageUrl, history }) {
  return (
    <div className="fdn-home-card">
      <Card className="fdn-home-card__surface">
        <CardPrimaryAction
          onClick={() => {
            if (href) history.push(href);
          }}
        >
          <CardMedia
            sixteenByNine
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
          <div className="fdn-home-card__content">
            <h1 className="fdn-home-card__header">{title}</h1>
            <p className="fdn-home-card__description">{description}</p>
          </div>
        </CardPrimaryAction>
      </Card>
    </div>
  );
}

const PersonaCard = withRouter(PersonaCardView);

export default withAuth(Home);
