import React, { useEffect, createContext, useContext } from 'react';
import config from '../../config/config';

const CaptchaContext = createContext({});

function CaptchaProvider({ children }) {
  // Initialize the captcha script
  useEffect(() => {
    const captchaScript = document.createElement('script');
    captchaScript.src = `https://www.google.com/recaptcha/api.js?render=${config.captchaSiteKey}`;
    document.body.appendChild(captchaScript);
  }, []);

  const captcha = window['grecaptcha'];

  function execute(action, callback) {
    captcha.ready(() => {
      captcha.execute(config.captchaSiteKey, { action }).then(callback);
    });
  }

  return (
    <CaptchaContext.Provider value={{ captcha, execute }}>
      {children}
    </CaptchaContext.Provider>
  );
}

function withCaptcha(WrappedComponent) {
  return ({ children, ...props }) => {
    const captcha = useContext(CaptchaContext);
    return (
      <WrappedComponent captcha={captcha} {...props}>
        {children}
      </WrappedComponent>
    );
  };
}

export { CaptchaProvider, withCaptcha, CaptchaContext };
