import React from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import { CardWrapper, CardBody, CardContent, CardFooter } from 'cdk-radial';
import { BarChart } from './BarChart';

export const BarChartCard = ({
  metricRecord,
  isLoading,
  isRequestFulfilled,
  error
}) => {
  return (
    <div>
      {!isRequestFulfilled && !!isLoading && (
        <CardWrapper className="bar-chart-metric-card">
          {isLoading && (
            <div className="fdn-metric--empty-space">
              <CircularProgress />
            </div>
          )}
          <CardBody>
            <CardContent></CardContent>
            <CardFooter />
          </CardBody>
        </CardWrapper>
      )}

      {!!error && (
        <CardWrapper className="bar-chart-metric-card">
          <div className="fdn-metric--empty-space">
            Sorry, we are unable to load metrics at this time. Please try again
            later.
          </div>
          <CardBody>
            <CardContent></CardContent>
            <CardFooter />
          </CardBody>
        </CardWrapper>
      )}
      {!isLoading && (
        <CardWrapper className="bar-chart-metric-card">
          <CardBody>
            <CardContent>
              {metricRecord && !metricRecord.length && (
                <div>
                  <br />
                  No API Traffic
                </div>
              )}
              {metricRecord && !!metricRecord.length && (
                <BarChart metricRecord={metricRecord} />
                // <div className="app-async-api-metric-body">
                //   <BarChart metricRecord={metricRecord} />
                // </div>
              )}
            </CardContent>
            <CardFooter />
          </CardBody>
        </CardWrapper>
      )}
    </div>
  );
};
