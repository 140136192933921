import React, { useEffect } from 'react';
import { Body1 } from '@fortellis/typography';
import { CircularProgress } from '@rmwc/circular-progress';

export default function ProgressSpinner({ fullScreen, message }) {
  useEffect(() => {
    if (fullScreen) {
      document.body.classList.add('frc-progress-spinner-scroll-lock');
      return () => {
        document.body.classList.remove('frc-progress-spinner-scroll-lock');
      };
    }
  }, [fullScreen]);
  return (
    <div className="frc-progress-spinner frc-progress-spinner--full-screen">
      <CircularProgress />
      {message && <Body1 style={{ marginLeft: '8px' }}>{message}</Body1>}
      <div className="frc-progress-spinner__scrim" />
    </div>
  );
}
