import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IconChevronLeft, IconOpenInNew } from '@cdk-rds/icons';
import { TextButton } from '@cdk-rds/button';
import ApiMetricsInput from '../../../metrics/ApiMetricsInput';
import { Heading } from '@cdk-rds/typography';
import { Page, PageContent } from '@fortellis/page';
import { Body1, Headline5 } from '@fortellis/typography';
import { Grid, Row, Column } from 'cdk-radial';
import {
  useApiFetch,
  useAsyncApiEventsFetch,
  useAsyncApiFailedEventsFetch,
  useAsyncAppApiDataFetch
} from '../../../metrics/fetchMetricsContext';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { apiTypes } from '../constants';
import config from '../../../../config/config';
import { AsyncApiCountCard } from '../../../metrics/AsyncApiCountCard';
import { AsyncApiDeliveryTable } from '../../../metrics/AsyncApiDeliveryTable';
import axios from 'axios';

const ASYNC_API_BASE_URL = config.api.asyncApiServiceBaseUrl;

function ApiMetrics({ auth, match, location = {} }) {
  const [timeRange, setTimeRange] = useState('24h');
  const [refresh, triggerRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState('loading');
  const [filters, setFilters] = useState({
    timeRange,
    apiId: match.params.id
  });
  const [allAsyncCounts, setAllAsyncCounts] = useState({
    published: 0,
    success: 0,
    failure: 0
  });
  const [deliveryDetails, setDeliveryDetails] = useState([]);
  const [integratedApps, setIntegratedApps] = useState([]);
  const history = useHistory();
  const [
    apiDetailMetricsIsLoading,
    isApiDetailMetricsRequestFulfilled,
    apiDetailMetricsError,
    apiDetailMetricsResponse,
    fetchApiDetailMetrics
  ] = useAsyncApiEventsFetch();

  const [
    apiDetailFailedMetricsIsLoading,
    isApiDetailFailedMetricsRequestFulfilled,
    apiDetailFailedMetricsError,
    apiDetailFailedMetricsResponse,
    fetchApiDetailFailedMetrics
  ] = useAsyncApiFailedEventsFetch();

  const [
    apiInfoIsLoading,
    isApiInfoRequestFulfilled,
    apiInfoError,
    apiInfoData,
    fetchApi
  ] = useApiFetch({});

  const [
    appApiMetricsIsLoading,
    isAppApiMetricsRequestFulfilled,
    appApiMetricsError,
    appApiMetricsResponse,
    fetchAppApiDetailMetrics
  ] = useAsyncAppApiDataFetch();

  function onFilterChange(change) {
    setFilters({ ...filters, ...change });
  }

  const getApiAppIntegrations = async () => {
    return await axios({
      method: 'GET',
      url: `${ASYNC_API_BASE_URL}/v1/async-api-integrations?appDetails=true&apiId=${match.params.id}`,
      headers: {
        Authorization: 'Bearer ' + auth.accessToken,
        'content-type': 'application/json'
      }
    })
      .then(response => {
        processIntegratedApps(response.data);
      })
      .catch(ex => {
        console.error(
          'failed to load api app integrations data with error ' + ex
        );
      });
  };

  const processIntegratedApps = ({ items: connections }) => {
    let localApp = [];
    !!connections &&
      Array.isArray(connections) &&
      connections.forEach(app => {
        localApp = [
          ...localApp,
          {
            appId: app?.app?.id,
            appName: app?.appName,
            env: 'prod'
          }
        ];
      });

    setIntegratedApps(localApp);
  };

  useEffect(() => {
    if (isLoading === 'loading' && !!apiInfoData) {
      onFilterChange({
        ...filters
      });
      setIsLoading('loaded');
      triggerRefresh(!refresh);
    }
  }, [apiInfoIsLoading, apiInfoError, apiInfoData]);

  useEffect(
    function() {
      if (auth.isAuthenticated) {
        fetchApi(auth.accessToken || '', match.params.id, apiTypes.ASYNC);
        getApiAppIntegrations();
      }
    },
    [auth.isAuthenticated]
  );

  useEffect(
    function() {
      // Prevent api call during initial rendering
      if (isLoading !== 'loading' && auth.isAuthenticated) {
        fetchAppApiDetailMetrics(auth.accessToken || '', filters);
        setTimeRange(filters.timeRange);
      }
    },
    [refresh, auth.isAuthenticated]
  );

  useEffect(() => {
    if (!!appApiMetricsResponse) {
      const pubCount =
        appApiMetricsResponse
          .filter(f => f.operation === 'send')
          .reduce((a, b) => a + parseInt(b.count), 0) || 0;
      const sDeliveryCount =
        appApiMetricsResponse
          .filter(f => f.operation === 'receive' && f.status === 'success')
          .reduce((a, b) => a + parseInt(b.count), 0) || 0;
      const fDeliveryCount =
        appApiMetricsResponse
          .filter(f => f.operation === 'receive' && f.status === 'failure')
          .reduce((a, b) => a + parseInt(b.count), 0) || 0;

      const receiveData = appApiMetricsResponse.filter(
        f => f.operation === 'receive'
      );

      setAllAsyncCounts({
        published: pubCount,
        success: sDeliveryCount,
        failure: fDeliveryCount
      });

      setDeliveryDetails(receiveData);
    }
  }, [appApiMetricsResponse]);

  return (
    <>
      <div className="fdn-back-button">
        <div className="fdn-back-button-content">
          <TextButton
            onClick={() => history.goBack()}
            icon={<IconChevronLeft />}
            text="Back"
          />
        </div>
      </div>
      <Page className="frc-page__metrics">
        <PageContent>
          <div className="api-instance-metrics">
            <Heading headingType="heading-1" level={1}>
              {(apiInfoData && apiInfoData.name) || 'Loading'}
            </Heading>
            <br />
            <Headline5 weight={500}>Published Events Metrics</Headline5>
            <br />
            <div>
              <Body1 weight={400} className="async-api-metrics-copy">
                The number of original events published and the sum of the
                failed broadcasts of those events. When not received, some
                events can be broadcast multiple times.{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${config.nav.docs.url}/docs/tutorials/event-relay/monitoring-your-asynchronous-api/`}
                  style={{ fontWeight: 600, color: '#6b2574' }}
                >
                  Learn more
                  <IconOpenInNew
                    height={15}
                    weight={15}
                    style={{ fill: '#6b2574' }}
                  />
                </a>
              </Body1>
            </div>
            <br />
            <ApiMetricsInput
              refresh={refresh}
              triggerRefresh={triggerRefresh}
              onFilterChange={onFilterChange}
              filters={filters}
              isLoading={apiDetailMetricsIsLoading || apiInfoIsLoading}
              integratedApps={integratedApps}
              apiType={apiTypes.ASYNC}
            />
            <Grid>
              <Row style={{ gap: '16px' }}>
                <Column>
                  <AsyncApiCountCard
                    type="published"
                    isLoading={appApiMetricsIsLoading}
                    isFulfilled={isAppApiMetricsRequestFulfilled}
                    isError={appApiMetricsError}
                    asyncCounts={allAsyncCounts}
                    timeRange={timeRange}
                  />
                </Column>
                <Column>
                  <AsyncApiCountCard
                    type="success"
                    isLoading={appApiMetricsIsLoading}
                    isFulfilled={isAppApiMetricsRequestFulfilled}
                    isError={appApiMetricsError}
                    asyncCounts={allAsyncCounts}
                    timeRange={timeRange}
                  />
                </Column>
                <Column>
                  <AsyncApiCountCard
                    type="failure"
                    isLoading={appApiMetricsIsLoading}
                    isFulfilled={isAppApiMetricsRequestFulfilled}
                    isError={appApiMetricsError}
                    asyncCounts={allAsyncCounts}
                    timeRange={timeRange}
                  />
                </Column>
              </Row>
              <Row style={{ gap: '16px' }}>
                <AsyncApiDeliveryTable apiDeliveries={deliveryDetails} />
              </Row>
            </Grid>
          </div>
        </PageContent>
      </Page>
    </>
  );
}

function ApiMetricsContainer(props) {
  return props.auth.isAuthenticated ? <ApiMetrics {...props} /> : <></>;
}
export default withAuth(ApiMetricsContainer);
