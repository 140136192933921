import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import {
  Loader,
  CardWrapper,
  CardBody,
  CardContent,
  CardFooter,
  CardHeader
} from 'cdk-radial';
import { LineSeriesChart, LineSeriesChartLegend } from './LineSeriesChart';
import { singlePointNumericFormatter } from './utils';

export function LineSeriesChartCard({
  config,
  title,
  yAxiscounterName,
  url,
  refresh,
  data,
  isLoading,
  error,
  isRequestFulfilled = true,
  transactionCount,
  timeRange
}) {
  let clientErrorCountCopy;
  let serverErrorCountCopy;
  if (
    title === 'Error Rates' &&
    transactionCount &&
    data &&
    data.length &&
    data[0].clientErrorCount
  ) {
    clientErrorCountCopy = `4xx: ${singlePointNumericFormatter(
      data[0].clientErrorCount
    )} (${Math.round(
      (Number(data[0].clientErrorCount) / Number(transactionCount)) * 100 * 100
    ) / 100}% of total transactions)`;
    serverErrorCountCopy = `5xx: ${singlePointNumericFormatter(
      data[0].serverErrorCount
    )} (${Math.round(
      (Number(data[0].serverErrorCount) / Number(transactionCount)) * 100 * 100
    ) / 100}% of total transactions)`;
  }
  return (
    <>
      {!isRequestFulfilled && !!isLoading && (
        <CardWrapper className="metric-card">
          {isLoading && (
            <div className="fdn-metric--empty-space">
              <CircularProgress />
            </div>
          )}
          <CardBody>
            <CardContent></CardContent>
            <CardFooter />
          </CardBody>
        </CardWrapper>
      )}

      {!!error && (
        <CardWrapper className="metric-card">
          <div className="fdn-metric--empty-space">
            Sorry, we are unable to load metrics at this time. Please try again
            later.
          </div>
          <CardBody>
            <CardContent></CardContent>
            <CardFooter />
          </CardBody>
        </CardWrapper>
      )}

      {!isLoading && data && config && (
        <CardWrapper className="metric-card">
          <CardBody>
            <CardContent>
              <div className="display-inline">
                <div className="display-flex">
                  <div className="line-chart-header">
                    <CardHeader title={title}></CardHeader>
                    {config.chart.yAxiscounterName}
                  </div>
                  {title === 'Error Rates' && (
                    <div className="display-flex total-errors-data">
                      {clientErrorCountCopy} {'\n'}
                      {serverErrorCountCopy}
                    </div>
                  )}
                </div>
                {data && !isLoading && !data.length && (
                  <div>
                    <br />
                    No API Traffic
                  </div>
                )}
                {data && !!data.length && config.chart.showLegend && (
                  <div className="line-chart-legend">
                    <LineSeriesChartLegend config={config} data={data} />
                  </div>
                )}
              </div>
              {data && !!data.length && (
                <LineSeriesChart config={config} data={data} />
              )}
            </CardContent>
            <CardFooter />
          </CardBody>
        </CardWrapper>
      )}
    </>
  );
}
