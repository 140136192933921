import React from 'react';
import {
  IconStatusWarningExclamation,
  IconSchedule,
  IconStatusInformationI
} from '@cdk-rds/icons';
import { apiTypes } from '../developer-account/api/constants';

export const ALERT_LEVELS = {
  SEVERE: 'severe',
  WARN: 'warn',
  NORMAL: 'normal',
  LOW: 'low'
};

const ALERT_THRESHOLDS = {
  ERROR_RATE_SEVERE: 50,
  ERROR_RATE_WARN: 20,
  LATENCY_SEVERE: 5000,
  LATENCY_WARN: 2000,
  TRAFFIC_LOW: 10
};

const EXTRACT_FIRST_DECIMAL_IN_FORMATTED_NUMERIC_REGEX = /([0-9]+)\.([0-9])([M,K,B])/;
const EXTRACT_DECIMAL_REGEX = /([0-9]+)\.([0-9]+)/;

/* 
TRAFFIC_COUNT_THRESHOLD_RATE represents the rate at which Y axis limit gets auto-incremented.
Ex: [0.5, 1] will create slabs as [50, 100, 500, 1000,.etc] // assumes lower threshold is >= 50
    [0.25, 0.5, 1] will create slabs as [50, 100, 250, 500, 1000, 2500, 5000,.etc] // assumes lower threshold is >= 50
*/
const TRAFFIC_COUNT_THRESHOLD_RATE = [0.5, 1];
/* 
TRAFFIC_COUNT_LOWER_THRESHOLD represents the possible lowest preset value for Y Axis limit
Ex: If there are only 5 transactions we need to plot at a specfic timestamp, having Y axis limit to be 50 would be better than 10,000.
*/
const TRAFFIC_COUNT_LOWER_THRESHOLD = 50;
/* 
TRAFFIC_COUNT_UPPER_THRESHOLD represents the possible highest preset value for Y Axis limit, current limit is 5 Billion.
Note: If an give data point goes beyond this limit, we automatically extend the limit to 125% of the highest data point available so that all data points are plottable.
*/

const TRAFFIC_COUNT_UPPER_THRESHOLD = 5000000000;

function removeDecimalWhenZero(input) {
  const [inputValue, numeric, decimal] = EXTRACT_DECIMAL_REGEX.exec(input);
  if (decimal === '0') {
    // Truncate the decimal if its 0 so its'll be 800M instead of 800.0M
    return input.replace('.0', '');
  }
  return input;
}

function setAlertStatus(statusMap, alertMessage, level) {
  if (!statusMap[level]) {
    statusMap[level] = [];
  }
  statusMap[level].push(alertMessage);
}

function formatMilliSeconds(ms) {
  return (Math.abs(Number(ms)) / 1.0e3).toFixed(0) + 's';
}

function setErrorRateAlertStatus(statusMap, errorRate) {
  let alertMessagePrefix = 'Error rate';
  let currentRate = parseInt(errorRate);
  if (errorRate > ALERT_THRESHOLDS.ERROR_RATE_SEVERE) {
    setAlertStatus(
      statusMap,
      `${alertMessagePrefix}: ${currentRate}% (> ${ALERT_THRESHOLDS.ERROR_RATE_SEVERE}% threshold)`,
      ALERT_LEVELS.SEVERE
    );
  } else if (errorRate > ALERT_THRESHOLDS.ERROR_RATE_WARN) {
    setAlertStatus(
      statusMap,
      `${alertMessagePrefix}: ${currentRate}% (> ${ALERT_THRESHOLDS.ERROR_RATE_WARN}% threshold)`,
      ALERT_LEVELS.WARN
    );
  } else {
    setAlertStatus(
      statusMap,
      `${alertMessagePrefix}: ${currentRate}%`,
      ALERT_LEVELS.NORMAL
    );
  }
}

function setLatencyAlertStatus(statusMap, p90Latency) {
  let alertMessagePrefix = 'P90 Latency';
  if (p90Latency > ALERT_THRESHOLDS.LATENCY_SEVERE) {
    setAlertStatus(
      statusMap,
      `${alertMessagePrefix}: ${p90Latency}ms (> ${formatMilliSeconds(
        ALERT_THRESHOLDS.LATENCY_SEVERE
      )} threshold)`,
      ALERT_LEVELS.SEVERE
    );
  } else if (p90Latency > ALERT_THRESHOLDS.LATENCY_WARN) {
    setAlertStatus(
      statusMap,
      `${alertMessagePrefix}: ${p90Latency}ms (> ${formatMilliSeconds(
        ALERT_THRESHOLDS.LATENCY_WARN
      )} threshold)`,
      ALERT_LEVELS.WARN
    );
  } else {
    setAlertStatus(
      statusMap,
      `${alertMessagePrefix}: ${p90Latency}ms`,
      ALERT_LEVELS.NORMAL
    );
  }
}

function getHighestAlertLevel(statusMap) {
  if (statusMap[ALERT_LEVELS.SEVERE] && statusMap[ALERT_LEVELS.SEVERE].length) {
    return ALERT_LEVELS.SEVERE;
  } else if (
    statusMap[ALERT_LEVELS.WARN] &&
    statusMap[ALERT_LEVELS.WARN].length
  ) {
    return ALERT_LEVELS.WARN;
  } else {
    return ALERT_LEVELS.NORMAL;
  }
}

export function getAlertIcons(statusMap) {
  let levels = Object.keys(statusMap);
  let icons = [];
  for (let level of levels) {
    if (statusMap[level].length) {
      let alertMessage = statusMap[level].join(', ');
      if (level === ALERT_LEVELS.SEVERE) {
        icons.push(
          <IconStatusWarningExclamation
            className="icon-danger"
            alertmessage={alertMessage}
          />
        );
      } else if (level === ALERT_LEVELS.WARN) {
        icons.push(
          <IconSchedule className="icon-warning" alertmessage={alertMessage} />
        );
      } else if (level === ALERT_LEVELS.NORMAL) {
        icons.push(
          <IconStatusInformationI
            className="icon-trending-up"
            alertmessage={alertMessage}
          />
        );
      }
    }
  }
  return icons;
}

export function getAlertStatus(data) {
  const statusMap = {};
  setErrorRateAlertStatus(statusMap, data.errorRate);
  setLatencyAlertStatus(statusMap, data.p90Latency);
  return {
    statusMap,
    alertLevel: getHighestAlertLevel(statusMap)
  };
}

export const ENVIRONMENTS = [
  { label: 'Production', value: 'prod' },
  { label: 'Test', value: 'test' },
  { label: 'Dev', value: 'dev' }
];

export const API_ENVS_FOR_APPS = [
  { label: 'Production', value: 'prod' },
  { label: 'Test', value: 'test' }
];

export function getEnvOption(env) {
  return ENVIRONMENTS.find(each => each.value === env);
}

export const TIME_SPANS = [
  { label: 'Last 10 min', value: '10m' },
  { label: 'Last 60 min', value: '60m' },
  { label: 'Last 24 hours', value: '24h' },
  { label: 'Last 7 days', value: '7d' },
  { label: 'Last 30 days', value: '30d' }
];

export const TIME_SPANS_ASYNC_API = [
  { label: 'Last 10 min', value: '10m' },
  { label: 'Last 60 min', value: '60m' },
  { label: 'Last 24 hours', value: '24h' },
  { label: 'Last 7 days', value: '7d' }
];

// FORTMP-3595: Only have option for P90 right now
export const LATENCIES = [
  { label: 'P90', value: 'p90Latency', disabled: true }
];

export const ALL_APIS_OPTION = {
  label: 'All APIs',
  value: 'ALL_APIS'
};

export const METRICS_SOURCE = [
  { label: 'API Provider', value: 'api_provider' },
  { label: 'Fortellis', value: 'fortellis', isDisabled: true }
];

export function getimeSpanOption(time, apiType) {
  return apiType === apiTypes.ASYNC
    ? TIME_SPANS_ASYNC_API.find(each => each.value === time)
    : TIME_SPANS.find(each => each.value === time);
}

export function getIntervalFromTimeRange(timeRange) {
  let interval = '1h';
  switch (timeRange) {
    case '10m':
      interval = '1m';
      break;
    case '60m':
      interval = '6m';
      break;
    case '24h':
      interval = '2h';
      break;
    case '7d':
      interval = '1d';
      break;
    case '30d':
      interval = '1d';
      break;
  }
  return interval;
}

/* Following function generates threshold based on given rate, min & max thresholds
   Each threshold represent a possible values for Y Axis limit, appropriate slab is selected based on the highest value of the available data points.
   Why do we need slabs ??
     Adaptive thresholds helps users easily comprehend data points in the visualization. Fixed thresholds will either makes the datapoints invisible or makes then unplottable.  
   Ex: Lets assume user wants to view API Traffic for 2 time windows
          1. Last 10 mins - If highest number of transactions at anytime is no greater than 1000, Y Axis range of [0, 5K] would be effective than [0, 5M], latter(5 million) makes is very hard to observe data points in the range of ~1000.
          2. Last 30 days - If highest number of transactions at anytime is beyond 1M, Y Axis range of [0, 5000] just wouldn't work, we'll need [0, 5M].
     Points to Note: A Fixed threshold won't suffice different time windows.
*/
function getAdaptiveThresholds(rate, lowerThreshold, UpperThreshold) {
  let thresholds = [];
  let isThresholdMet = false;
  let j = 1;
  while (!isThresholdMet) {
    const thresholdsForInterval = rate.map(each => each * j);
    for (let eachValue of thresholdsForInterval) {
      // Select the threshold if they fall within desired thresholds
      if (eachValue >= lowerThreshold && eachValue <= UpperThreshold) {
        thresholds.push(eachValue);
      }
      // Exist when the max threshold is met
      if (eachValue > UpperThreshold) {
        isThresholdMet = true;
      }
    }
    j = j * 10;
  }
  return thresholds;
}

const trafficCountThresholds = getAdaptiveThresholds(
  TRAFFIC_COUNT_THRESHOLD_RATE,
  TRAFFIC_COUNT_LOWER_THRESHOLD,
  TRAFFIC_COUNT_UPPER_THRESHOLD
);

// Build lower and upper bounds for each of the thresholds to help determine which threshold to pick based on input data points max value
// Using a lower bound of 20% and upper bound of 90% helps plotsthe data point not too far low and not too far high on the x-axis making it easier to follow for users.
const ADAPTIVE_TRAFFIC_THRESHOLDS = trafficCountThresholds.map(each => ({
  threshold: parseInt(each),
  lowerBound: (parseInt(each) / 100) * 20, // Lower bound is 20% of input threshold
  upperBound: (parseInt(each) / 100) * 90 // Lower bound is 90% of input threshold
}));

function getAdaptiveThresholdHelper(inputThresholds) {
  // Following module will return the appropriate threshold for Y Axis max value value based on the the high data point for better visualization
  return function thresholdHelper(input) {
    let parsedInput = parseInt(input);
    // Return the lowest threshold as default when input does not meet the lowest slab.
    if (parsedInput < inputThresholds[0].lowerBound) {
      return inputThresholds[0].threshold;
    }
    const thresholdMatch = inputThresholds.find(
      each => parsedInput >= each.lowerBound && parsedInput <= each.upperBound
    );
    if (thresholdMatch) {
      return thresholdMatch.threshold;
    }
    // Return 125% of provided input if the values goes over the max threshold defined, if not, the data point goes beyon Y axis bounds making it un-plottable.
    return (parsedInput / 100) * 25 + parsedInput;
  };
}

// Following module helps find the max value for y axis based on the highest value in data points
export const getAdaptiveTransactionCountMaxThreshold = getAdaptiveThresholdHelper(
  ADAPTIVE_TRAFFIC_THRESHOLDS
);

// Latency thresholds for 5s, 10s, 15s, 30s, 1m, 5m, 10m, 15m, 20m
const ADAPTIVE_LATENCY_THRESHOLDS = [
  5000,
  10000,
  15000,
  30000,
  60000,
  300000,
  600000,
  900000,
  1200000
].map(each => ({
  threshold: parseInt(each),
  lowerBound: (parseInt(each) / 100) * 20, // Lower bound is 20% of input threshold
  upperBound: (parseInt(each) / 100) * 95 // Lower bound is 95% of input threshold
}));

// Following module helps find the max value for y axis based on the highest value in data points
export const getAdaptiveLatencyThreshold = getAdaptiveThresholdHelper(
  ADAPTIVE_LATENCY_THRESHOLDS
);

/* 
Following module formats numeric counts into thousand(K), million(M) & billion(B) format for given number of digits after decimal
   Ex   Input(count,digits)   Output
              1200, 1          1.2K
              1000, 0          1K
           5000000, 1          5.0M
           5000000, 0          5M
        7200000000, 1          7.2B
*/
function fixedPointNumericFormatter(digits) {
  return function numericFormatter(count) {
    let absValue = Math.abs(Number(count));
    if (absValue >= 1.0e9) {
      return (absValue / 1.0e9).toFixed(digits) + 'B';
    } else if (absValue >= 1.0e6) {
      return (absValue / 1.0e6).toFixed(digits) + 'M';
    } else if (absValue >= 1.0e3) {
      return (absValue / 1.0e3).toFixed(digits) + 'K';
    } else {
      return absValue;
    }
  };
}

/* 
Following module formats latency counts into millisecods(ms), minutes(m) format for given input
   Ex         Input(Milliseconds)   Output
                 690                 690ms
                9000                  9s
              120000                  2m
               65000                 1m5s
*/
export function latencyNumericFormatter(latencyInMilliseconds) {
  let absValue = Math.abs(Number(latencyInMilliseconds));
  if (absValue === 0) {
    return '';
  }
  if (absValue >= 60.0e3) {
    let minutes = parseInt(absValue / 60.0e3);
    let seconds = (absValue % 60.0e3) / 1.0e3;
    return `${minutes}m${!!seconds ? seconds + 's' : ''}`;
  } else if (absValue >= 1.0e3) {
    return removeDecimalWhenZero((absValue / 1.0e3).toFixed(1)) + 's';
  } else {
    return absValue + 'ms';
  }
}

export const singlePointDecimalNumericFormatter = fixedPointNumericFormatter(1);

export function singlePointNumericFormatter(input) {
  // Return input when less than 1000 as not formatting nescessary
  if (input < 1.0e3) {
    return input;
  }
  let result = singlePointDecimalNumericFormatter(input);
  // Single point precision will always follow the pattern {number}.{decimal}{B | M | K}
  const [
    inputValue,
    numeric,
    decimal
  ] = EXTRACT_FIRST_DECIMAL_IN_FORMATTED_NUMERIC_REGEX.exec(result);
  if (decimal === '0') {
    // Truncate the decimal if its 0 so its'll be 800M instead of 800.0M
    return result.replace('.0', '');
  }
  return result;
}

const TIMESTAMP_REGEX = /([0-9]+)\-([0-9]+)\-([0-9]+)\ ([0-9]+):([0-9]+):([0-9]+).([0-9]+)/;

export function getISOFormattedUTCDateString(datetime) {
  let [, YYYY, MM, DD, H, M, S, MS] = TIMESTAMP_REGEX.exec(datetime);
  return `${MM}/${DD}/${YYYY} ${H}:${M}:${S} GMT-0000`;
}
