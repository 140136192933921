import React, { useState, useEffect } from 'react';
import { Grid, Column, Row } from 'cdk-radial';
import { IconChevronLeft } from '@cdk-rds/icons';
import { TextButton } from '@cdk-rds/button';
import ApiMetricsInput from '../../../metrics/ApiMetricsInput';
import { LineSeriesChartCard } from '../../../metrics/LineSeriesChartCard';
import { Heading } from '@cdk-rds/typography';
import { Page, PageContent } from '@fortellis/page';
import { Body1, Headline5 } from '@fortellis/typography';
import axios from 'axios';
import config from '../../../../config/config';
import LearnMore from '../../../learnmore/LearnMore';
import {
  useApiMetricsFetch,
  useApiFetch
} from '../../../metrics/fetchMetricsContext';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import {
  getAdaptiveTransactionCountMaxThreshold,
  singlePointNumericFormatter,
  getAdaptiveLatencyThreshold,
  latencyNumericFormatter
} from '../../../metrics/utils';
import './api-metrics.scss';
import { useHistory } from 'react-router-dom';

const SUBSCRIPTION_URL = config.api.subscriptionUrl;
const APIGATEWAY_URL = config.api.apiGatewayUrl;

function latencyCrossHairFormatter(input) {
  return `${input}ms`;
}
function errorRateCrossHairFormatter(input) {
  return `${input}%`;
}

function getAdaptiveTrafficChartConfig(maxValue = 500, totalCount) {
  return {
    chart: {
      height: 200,
      width: 400,
      yAxiscounterName: `Total Requests${totalCount ? ': ' : ''} ${
        totalCount ? singlePointNumericFormatter(Number(totalCount)) : ''
      }`,
      yAxisCounterRange: [0, getAdaptiveTransactionCountMaxThreshold(maxValue)],
      yAxisTickFormat: singlePointNumericFormatter,
      yAxisTicksTotal: 5,
      xAxisTicksTotal: 7,
      lineConfig: {
        count: {
          stroke: '#1EAD47',
          label: 'Request Count',
          hoverText: 'Requests'
        }
      },
      showLegend: false,
      showCrossHairs: true,
      crossHairValueFormatter: singlePointNumericFormatter
    }
  };
}
function getAdaptiveLatencyChartConfig(maxValue = 10000) {
  return {
    chart: {
      height: 200,
      width: 400,
      yAxiscounterName: 'Milliseconds',
      yAxisCounterRange: [0, getAdaptiveLatencyThreshold(maxValue)],
      yAxisTickFormat: latencyNumericFormatter,
      xAxisTicksTotal: 7,
      yAxisTicksTotal: 5,
      lineConfig: {
        p99Latency: {
          stroke: '#DA253C',
          label: 'P99',
          hoverText: 'P99 Latency'
        },
        p95Latency: {
          stroke: '#1192E8',
          label: 'P95',
          hoverText: 'P95 Latency'
        },
        p90Latency: {
          stroke: '#1EAD47',
          label: 'P90',
          hoverText: 'P90 Latency'
        }
      },
      showLegend: true,
      showCrossHairs: true,
      crossHairValueFormatter: latencyNumericFormatter
    }
  };
}
const latencyChartConfig = {
  chart: {
    height: 200,
    width: 400,
    yAxiscounterName: 'Milliseconds',
    yAxisCounterRange: [0, 9000],
    yAxisTickFormat: latencyNumericFormatter,
    xAxisTicksTotal: 7,
    yAxisTicksTotal: 5,
    lineConfig: {
      p99Latency: { stroke: '#DA253C', label: 'P99', hoverText: 'P99 Latency' },
      p95Latency: { stroke: '#1192E8', label: 'P95', hoverText: 'P95 Latency' },
      p90Latency: { stroke: '#1EAD47', label: 'P90', hoverText: 'P90 Latency' }
    },
    showLegend: true,
    showCrossHairs: true,
    crossHairValueFormatter: latencyCrossHairFormatter
  }
};
const errorRatesChartConfig = {
  chart: {
    height: 200,
    width: 400,
    yAxiscounterName: 'Percentage of all Transactions',
    yAxisCounterRange: [0, 100],
    xAxisTicksTotal: 7,
    yAxisTicksTotal: 5,
    lineConfig: {
      clientErrorRate: { stroke: '#1EAD47', label: '4xx', hoverText: '4xx' },
      serverErrorRate: { stroke: '#DA253C', label: '5xx', hoverText: '5xx' }
    },
    showLegend: true,
    showCrossHairs: true,
    crossHairValueFormatter: errorRateCrossHairFormatter
  }
};

function getApiVersionsByEnvMapping(apiData = {}) {
  const data = {};
  (apiData.environments || []).forEach(env => {
    let availableVersions = env.instances.map(
      eachInstance => eachInstance.version
    );
    data[env.name] = availableVersions;
  });
  return data;
}

function ApiMetrics({ auth, match, location = {} }) {
  const { proxyRevision = '', environment = 'prod', timeRange = '24h' } =
    location.state || {};
  const [refresh, triggerRefresh] = useState(false);
  const [filters, setFilters] = useState({
    environment,
    timeRange,
    apiId: match.params.id,
    apiInstanceVersion: 'Loading'
  });
  const [integratedApps, setIntegratedApps] = useState([]);
  const [connectionsData, setConnectionsData] = useState([]);
  const [apiIntegrationsData, setApiIntegrationsData] = useState([]);
  const [
    apiDetailMetricsIsLoading,
    isApiDetailMetricsRequestFulfilled,
    apiDetailMetricsError,
    apiDetailMetricsResponse,
    fetchApiDetailMetrics
  ] = useApiMetricsFetch();
  const { transactions = '', latency = '', errorRate = '' } =
    apiDetailMetricsResponse || {};
  const [
    apiInfoIsLoading,
    isApiInfoRequestFulfilled,
    apiInfoError,
    apiInfoData,
    fetchApi
  ] = useApiFetch({});
  const apiVersionsByEnvMapping = getApiVersionsByEnvMapping(apiInfoData || {});
  const availableVersions = apiVersionsByEnvMapping[filters.environment] || [];
  const [loadIntegrationData, setLoadIntegrationData] = useState(false);
  const [loadConnectionsData, setLoadConnectionsData] = useState(false);
  const history = useHistory();

  function onFilterChange(change) {
    setFilters({ ...filters, ...change });
  }

  const getConnections = async () => {
    setLoadConnectionsData(true);
    await axios({
      method: 'GET',
      url: `${SUBSCRIPTION_URL}/connections/?providerId=api-v2-${match.params.id}`,
      headers: {
        Authorization: 'Bearer ' + auth.accessToken,
        'content-type': 'application/json'
      }
    })
      .then(result => {
        setConnectionsData(result.data.connections);
        setLoadConnectionsData(false);
      })
      .catch(error => {
        setLoadConnectionsData(false);
        console.error('failed to get connection data');
      });
  };

  const getApiAppIntegrations = async () => {
    setLoadIntegrationData(true);
    return await axios({
      method: 'GET',
      url: `${APIGATEWAY_URL}/api-integrations?apiId=${match.params.id}&includeIntegrations=separate&appDetails=true`,
      headers: {
        Authorization: 'Bearer ' + auth.accessToken,
        'content-type': 'application/json'
      }
    })
      .then(response => {
        setApiIntegrationsData(response.data.items);
        setLoadIntegrationData(false);
      })
      .catch(ex => {
        setLoadIntegrationData(false);
        console.error(
          'failed to load api app integrations data with error ' + ex
        );
      });
  };

  const processIntegratedApps = () => {
    let localApp = [];
    localApp = connectionsData
      .filter(
        itemA =>
          itemA.status === 'active' &&
          apiIntegrationsData.some(itemB => itemA.solutionId === itemB.appId)
      )
      .map(itemA => {
        const uniqueApp = apiIntegrationsData.find(
          itemB => itemA.solutionId === itemB.appId
        );
        return {
          appId: itemA.solutionId,
          appName: uniqueApp ? uniqueApp.appName : '',
          env: itemA.environment
        };
      });
    setIntegratedApps(localApp);
  };

  useEffect(() => {
    if (
      filters.apiInstanceVersion === 'Loading' &&
      !!apiInfoData &&
      !!apiInfoData.environments
    ) {
      onFilterChange({
        ...filters,
        apiInstanceVersion: proxyRevision || availableVersions[0]
      });
      triggerRefresh(!refresh);
    }
  }, [apiInfoIsLoading, apiInfoError, apiInfoData]);

  useEffect(
    function() {
      if (auth.isAuthenticated) {
        fetchApi(auth.accessToken || '', match.params.id);
        getConnections();
        getApiAppIntegrations();
      }
    },
    [auth.isAuthenticated]
  );

  useEffect(() => {
    if (
      !!connectionsData &&
      connectionsData.length > 0 &&
      !!apiIntegrationsData &&
      apiIntegrationsData.length > 0
    ) {
      processIntegratedApps();
    }
  }, [loadConnectionsData, loadIntegrationData]);

  useEffect(
    function() {
      // Prevent api call during initial rendering
      if (filters.apiInstanceVersion !== 'Loading' && auth.isAuthenticated) {
        fetchApiDetailMetrics(auth.accessToken || '', filters);
      }
    },
    [refresh, auth.isAuthenticated]
  );

  const maxLatencyValues =
    (latency && Array.isArray(latency) && latency.map(each => each.maxValue)) ||
    [];
  const latencymaxValue = Math.max(...maxLatencyValues, 0);

  return (
    <>
      <div className="fdn-back-button">
        <div className="fdn-back-button-content">
          <TextButton
            onClick={() => history.goBack()}
            icon={<IconChevronLeft />}
            text="Back"
          />
        </div>
      </div>
      <Page className="frc-page__metrics">
        <PageContent>
          <div className="api-instance-metrics">
            <Heading headingType="heading-1" level={1}>
              {(apiInfoData && apiInfoData.name) || 'Loading'}
            </Heading>
            <br />
            <Headline5 weight={500}>Performance Metrics</Headline5>
            <br />
            <div>
              <Body1 weight={400}>
                This is a detailed view of your API Performance over time.
                Adjust the dropdown options and refresh the view to drill into
                different specifics.{' '}
                <LearnMore
                  url={`${config.nav.docs.url}/docs/tutorials/api-lifecycle/api-metrics`}
                />
              </Body1>
            </div>
            <br />
            <ApiMetricsInput
              refresh={refresh}
              triggerRefresh={triggerRefresh}
              onFilterChange={onFilterChange}
              filters={filters}
              availableVersions={availableVersions}
              isLoading={apiDetailMetricsIsLoading || apiInfoIsLoading}
              integratedApps={integratedApps}
            />
            <Grid style={{ maxWidth: '1401px' }}>
              <Row style={{ gap: '16px' }}>
                <Column style={{ marginRight: '0px' }}>
                  <div className="Api-Traffic-Chart">
                    <LineSeriesChartCard
                      title="Traffic"
                      yAxiscounterName="Request / minute"
                      config={getAdaptiveTrafficChartConfig(
                        transactions &&
                          transactions[0] &&
                          transactions[0].maxValue,
                        transactions &&
                          transactions[0] &&
                          transactions[0].transactionCount
                      )}
                      refresh={refresh}
                      data={transactions}
                      isLoading={apiDetailMetricsIsLoading || apiInfoIsLoading}
                      isRequestFulfilled={
                        isApiDetailMetricsRequestFulfilled &&
                        isApiInfoRequestFulfilled
                      }
                      error={apiDetailMetricsError}
                    />
                  </div>
                </Column>
                <Column style={{ marginRight: '0px' }}>
                  <LineSeriesChartCard
                    title="Latency"
                    yAxiscounterName="Request / minute"
                    config={getAdaptiveLatencyChartConfig(latencymaxValue)}
                    refresh={refresh}
                    data={latency}
                    isLoading={apiDetailMetricsIsLoading || apiInfoIsLoading}
                    isRequestFulfilled={
                      isApiDetailMetricsRequestFulfilled &&
                      isApiInfoRequestFulfilled
                    }
                    error={apiDetailMetricsError}
                  />
                </Column>
                <Column style={{ marginRight: '0px' }}>
                  <LineSeriesChartCard
                    title="Error Rates"
                    yAxiscounterName="Request / minute"
                    config={errorRatesChartConfig}
                    refresh={refresh}
                    data={errorRate}
                    isLoading={apiDetailMetricsIsLoading || apiInfoIsLoading}
                    isRequestFulfilled={
                      isApiDetailMetricsRequestFulfilled &&
                      isApiInfoRequestFulfilled
                    }
                    error={apiDetailMetricsError}
                    transactionCount={
                      transactions &&
                      transactions[0] &&
                      transactions[0].transactionCount
                    }
                    timeRange={filters.timeRange}
                  />
                </Column>
              </Row>
            </Grid>
          </div>
        </PageContent>
      </Page>
    </>
  );
}

export default withAuth(ApiMetrics);
