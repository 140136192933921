import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Link,
  Content,
  CONTENT_TYPES,
  Dialog,
  BUTTON_VARIANTS
} from 'cdk-radial';

import config from '../../config/config';

const DEV_ACCT_PREFIX = '/developer-account/';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
      error: null
    };
  }

  backToDeveloperAccount = () => {
    this.props.history.push('/developer-account');
    this.setState({
      hasError: false,
      errorMessage: '',
      error: null
    });
  };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.message,
      error
    };
  }

  render() {
    if (this.state.hasError) {
      const path = this.props.location.pathname;
      // the developer account landing can be loaded by /developer-account
      // or /developer-account/, so just checking ^/developer-account/
      // doesn't tell us if we're on a subpage of developer-account,
      // but if the path is longer than the string '/developer-account/',
      // then we can assume that we are on a subpage, as more characters
      // follow that substring
      const isBeneathDevAcct =
        path.startsWith(DEV_ACCT_PREFIX) &&
        path.length > DEV_ACCT_PREFIX.length;

      const backButtonProps = {
        id: 'fdn-error-back',
        onClick: this.backToDeveloperAccount,
        text: 'Back to Developer Account',
        variant: BUTTON_VARIANTS.TEXT
      };
      const reloadButtonProps = {
        id: 'fdn-error-reload',
        onClick: () => {
          window.location.reload();
        },
        text: 'Reload Page',
        variant: BUTTON_VARIANTS.PRIMARY
      };

      const buttonsProps = isBeneathDevAcct
        ? [backButtonProps, reloadButtonProps]
        : [reloadButtonProps];

      return (
        <Dialog
          isOpen={true}
          id="fdn-error-display"
          title="Application Error"
          onClose={() => {}}
          hideCloseButton={true}
          buttonsProps={buttonsProps}
        >
          <Content type={CONTENT_TYPES.BODY_2}>
            An error has occurred in the Fortellis Developer Network
            application.
          </Content>
          <Content type={CONTENT_TYPES.BODY_2}>
            Please reload the page and try what you were doing again. If this
            error persists, contact{' '}
            <Link text="Fortellis Support" href={config.support.url} isInline />
            .
          </Content>
        </Dialog>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
